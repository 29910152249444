import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index'
import searchFilterAdapter from 'public/src/pages/components/FilterBar/adapter/searchFilterAdapter'
import { cutImg, isClient } from 'public/src/pages/trend/utils'

export const formatGoodsData = (goods, options) => {
  if (!goods?.goods_id) return goods
  if (goods.bffGoodsInfo) return goods
  return goodsTransformation(goods, options)
}

export const formatMatchingCardsData = (matchingCards = [], options) => {
  const goods = []
  for (let i = 0, len = matchingCards.length; i < len; i++) {
    const item = matchingCards[i]
    const { matchingCardThemeId, matchingCardId, matchingProductCount, matchingCardSkc, matchingCardImg, matchingProducts } = item || {}
    goods.push({
      histogram_image_url: matchingCardImg || '',
      related_goods_num: matchingProductCount || 0,
      related_goods: matchingProducts.map(item => formatGoodsData(item, options)),
      main_good: {
        goods_id: matchingCardId,
        goods_sn: matchingCardSkc,
      },
      theme_id: matchingCardThemeId,
    })
  }
  return goods
}

export const formatFilterData = (sourceData) => {
  if (sourceData?.bffFilterTrans || !sourceData?.bffFilterInfo) return sourceData
  searchFilterAdapter(sourceData, sourceData.bffFilterInfo)
  sourceData.bffFilterTrans = true
  // 精简数据
  // delete sourceData.bffFilterInfo
  return sourceData
}

/**
 * 格式化列表数据
 */
export const formatListData = ({
  list = [],
  formatGoodsDataOptions = {},
  customCateIds = '',
}) => {
  if (!Array.isArray(list)) return []
  return list.map(item => {
    const newItem = formatGoodsData(item, formatGoodsDataOptions)
    // 保留红人图字段
    if (item?.goodsRedManImages?.length) {
      newItem.goodsRedManImages = item.goodsRedManImages
    }
    // 保留非成衣评论字段
    if (item?.productMaterial?.bubbleLabelList?.length) {
      newItem.bubbleLabelList = item.productMaterial.bubbleLabelList
    }
    newItem.customCateIds = customCateIds
    return newItem
  }) || []
}

const cutMap = {
  'A': '750',
  'C': '180'
}

const thumbCutMap = {
  'A': '200',
  'C': '180',
}

/**
 * 格式化趋势词卡数据
 */
export const formatTrendCardInfo = ({
  trendCardInfo,
  styleType,
  formatGoodsDataOptions,
  locals = {},
}) => {
  if (!Array.isArray(trendCardInfo)) return []
  const { RESOURCE_SDK = {} } = isClient ? window.gbCommonInfo : locals
  const cutWidth = cutMap[styleType] || 750
  const thumbCutWidth = thumbCutMap[styleType] || 104
  return trendCardInfo.map(item => {
    return {
      ...item,
      products: styleType === 'C' ? formatGoodsData(item.products, formatGoodsDataOptions) : item.products,
      trendImgUrl: cutImg({
        imgUrl: item.trendImgUrl,
        designWidth: cutWidth,
        exp: `unCut=0#max=${cutWidth}`,
        locals: {
          RESOURCE_SDK,
        }
      }),
      thumbImgUrl: cutImg({
        imgUrl: item.trendImgUrl,
        designWidth: thumbCutWidth,
        exp: `unCut=0#max=${thumbCutWidth}`,
        locals: {
          RESOURCE_SDK,
        }
      }),
    }
  }) || []
}

/**
 * 格式化趋势词内容体数据
 */
export const formatTrendWrodData = ({
  trendWrodData = [],
  filterIds = [],
  formatGoodsDataOptions = {},
  customCateIds = '',
}) => {
  if (!Array.isArray(trendWrodData)) return []
  return trendWrodData
    .filter(item => !filterIds.some(id => id === item.trendId))
    .map(item => {
      const newItem = formatGoodsData(item.product, {
        ...(formatGoodsDataOptions || {}),
        hideSuggestedSalePrice: !!formatGoodsDataOptions?.suggestedSaleType,
      })
      newItem.customCateIds = customCateIds
      // TODO: 售罄字段处理
      newItem.stock = 999
      newItem.is_on_sale = 1
      return {
        ...item,
        product: newItem,
      }
    })
}

/**
 * 格式化趋势店铺内容体
 */
export const formatTrendStoreData = ({
  trendStoreData = [],
  formatGoodsDataOptions = {},
  customCateIds = '',
}) => {

  if (!Array.isArray(trendStoreData)) return []
  return trendStoreData.map(item => {
    return {
      ...item,
      shopRecProducts: item.shopRecProducts.map(product => {
        const newItem = formatGoodsData(product, {
          ...(formatGoodsDataOptions || {}),
          hideSuggestedSalePrice: !!formatGoodsDataOptions?.suggestedSaleType,
        })
        newItem.customCateIds = customCateIds
        // TODO: 售罄字段处理
        newItem.stock = 999
        newItem.is_on_sale = 1
        return newItem
      })
    }
  })
}

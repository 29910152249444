<template>
  <div
    class="rights-subtitle"
    :style="customStyle"
    v-html="title"
  >
  </div>
</template>

<script setup name="subtitle">
import { ref, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  customStyle: {
    type: Object,
    default: () => {},
  },
  title: {
    type: String,
    default: '',
  },
})

onMounted(() => {
})



onUnmounted(() => {
})
</script>
<style lang="less" scoped>
@import './style/common.less';
.rights-subtitle {
  // .center();
  font-size: .32rem;
  font-weight: 400;
  line-height: normal;
  display: inline-block;
  .single-line-overflow();
}
</style>

<template>
  <span
    class="rights-template"
    :style="{
      ...customStyle,
    }"
    v-html="hText"
  >
  </span>
</template>

<script setup name="template">
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { replaceSpanText } from 'public/src/pages/user_index/util'

const props = defineProps({
  customStyle: {
    type: Object,
    default: () => {},
  },
  text: {
    type: String,
    default: '',
  },
  cls: {
    type: String,
    default: '',
  },
  val: {
    type: String,
    default: '',
  },

})

const hText = computed(() => {
  if(!props.val) return props.text
  return replaceSpanText(props.text, props.cls, props.val, '')
})


</script>
<style lang="less">
@import './style/common.less';
.rights-card {
  &__benefit-text {
    font-size: .2933rem;
    font-weight: 700;
    margin-left: .0533rem;
  }
  &__benefit-num {
    color: #000;
    font-size: .4267rem;
    font-weight: 700;
  }
}

.rights-template {
  font-size: .24rem;
  // .center();
}
</style>

<template>
  <div>
    <!-- 游戏赠送钱包金额临过期提示 -->
    <ExpireGameMoneyTips
      v-if="moduleControls.showExpireGameMoney"
      :expire-money-tips="bottomTipsInfo?.walletTip"
      @router-push="routerPush"
      @hide-module="updateModule({ isHideModuleAction: true })"
    />
    <!-- 临过期优惠券 -->
    <ExpireCouponTips
      v-else-if="moduleControls.showExpireCouponTips"
      :tips-content="bottomTipsInfo?.expireCouponTotal"
      @router-push="routerPush"
      @hide-module="updateModule({ isHideModuleAction: true })"
    />
    <!-- 临过期积分 -->
    <ExpirePointTips
      v-else-if="moduleControls.showExpirePointTips"
      :tips-content="bottomTipsInfo?.expirePointTotal"
      @router-push="routerPush"
      @hide-module="updateModule({ isHideModuleAction: true })"
    />
    <!-- 新人权益，独立于前面的模块 -->
    <NewUserBenefits
      v-if="moduleControls.showNewUserBenefits"
      :new-user-benefits="newUserBenefits"
    />
  </div>
</template>

<script setup name="UserAssetsBottomContent">
import { ref, watch } from 'vue'
import ExpireGameMoneyTips from './ExpireGameMoneyTips.vue'
import ExpireCouponTips from './ExpireCouponTips.vue'
import ExpirePointTips from './ExpirePointTips.vue'
import NewUserBenefits from 'public/src/pages/user_index/components/NewUserBenefits/ModuleIndex.vue'
import { getUserAssetsBottomContent } from 'public/src/pages/user_index/hooks/user-assets-bottom'

const props = defineProps({
  readyToInit: {
    type: Boolean,
    default: false,
  },
  bottomTipsInfo: {
    type: Object,
    default: () => ({}),
  },
  newUserBenefits: {
    type: Object,
    default: null,
  },
})

const emits = defineEmits([
  'router-push',
])

const moduleControls = ref({})
watch(() => props.readyToInit, (isReady) => {
  if (!isReady) return
  updateModule()
})

const updateModule = ({
  isHideModuleAction = false,
} = {}) => {
  moduleControls.value = getUserAssetsBottomContent({
    readyToInit: props.readyToInit,
    expireTipsInfo: props.bottomTipsInfo,
    newUserBenefits: props.newUserBenefits,
    isHideModuleAction,
  })
}

const routerPush = (...args) => {
  emits('router-push', ...args)
}
</script>

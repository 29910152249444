<template>
  <section
    v-show="pageData"
    is-use-bff="1"
    class="c-index-me"
    :class="{ 'c-index-me_bg': isShowBg }"
  >
    <div>
      <div v-if="isCommonNoticeShow" class="common-notice-wrap">
        <CommonNotice
          :message="commonNotifyContent"
          @closeAlert="onCloseCccNotify"
          @showDetailEvt="showDetailEvt"
        />
      </div>
      <MeHeader
        :abt-info="abt"
        :user-level-info="getUserLevelInfo"
        @routerPush="routerPush"
        @openRiskDrawer="openRiskDrawer"
        @loginSucRedirection="loginSucRedirection"
      />
      <div class="me-scroll-wrap">
        <MainSection
          :is-page-data-ready="isPageDataReady"
          :show-refresh-loading="showRefreshLoading"
          :bottom-tips-info="userAssetsBottomTipsInfo"
          :user-rights-info="getPersonalCenterEntranceInfo"
          :personal-info="getPersonalInfo"
          :news-info="newsBarInfo"
          :route-info="routeInfo"
          @routerPush="routerPush"
        />
        <MyOrdersSection
          :personal-info="getPersonalInfo"
          :base-other-info="baseOtherInfo"
          :dynamic-service="getDynamicService"
          @routerPush="routerPush"
        />
        <ShoppingGuide v-if="showAfterMounted" />
        <MoreServicesSection
          :dynamic-service="getDynamicService"
          :show-item-reminder="showMoreServiceItemReminder"
          @routerPush="routerPush"
          @openRiskDrawer="openRiskDrawer"
        />
        <WishAndRecentTabs
          v-if="showAfterMounted"
          :is-login="isLogin"
          :language="language"
          :footer-rcmd-list-resolve-status="footerRcmdListResolveStatus"
        />
        <FooterRcmdList
          v-if="
            showAfterMounted && footerRcmdCccConfig && !firstTrendFlowPending
          "
          :ccc-config="footerRcmdCccConfig"
          :handle-insert-card-slot="fetchTrendFlowData"
          :card-slot-info-list="trendFlowData"
          :language="language"
          :shein-club-info="sheinClubInfo"
          :abt-info="abtSource"
          @cccReady="onCccReady"
        />
      </div>
    </div>
    <!-- risk dialog 暂时用旧的 -->
    <RiskDialog
      v-if="showAfterMounted"
      ref="riskDialog"
      @changeUserRisk="changeUserRisk"
    />
  </section>
</template>

<script>
/**
 * @file 个人中心容器组件
 */

import {
  onDeactivated,
  onActivated,
  defineComponent,
  defineExpose,
  getCurrentInstance,
  computed,
  watch,
  ref,
  onBeforeMount,
  onMounted,
  nextTick,
  provide,
  defineAsyncComponent
} from 'vue'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { markPoint } from 'public/src/services/mark/index.js'
// import { loadTdLibJs, __blackbox } from 'public/src/pages/common/RiskCommon/libs/BlackBox/index.js'
import { getQueryString } from '@shein/common-function'

import { JUMP_URL, RISK_PATH_WHITE_LIST } from './consts.js'
import { useMapState, useMapMutations } from './hooks/useVuex.js'
import Analysis from './analysis'

import { handleFetchUserIndexApiCache } from 'public/src/pre_requests/modules/user_index.js'

import { getUnionOrderListPageData, queryOrderStatusText } from './api'
import {
  getBffBaseData,
  isBffOrderList,
} from 'public/src/pages/user/child_pages/orders_bff/list/getBaseData/index.js'
import {
  initFooterRcmdListModule,
  FooterRcmdListResolveStatus,
} from 'public/src/pages/user_index/hooks/footer-rcmd-list'
import { initNewsListModule } from 'public/src/pages/user_index/hooks/news-list'
import { initCommonNotifyModule } from 'public/src/pages/user_index/hooks/common-notify'
import { getRedDotStatus } from './hooks/more-services/redDot'
import UserInfoManager from 'public/src/services/UserInfoManager'
import { useTrendFlow } from './hooks/index.js'
import { useUserCenterAnalysis } from './hooks/ana.js'
import { userOrdersListRouteComponent } from 'public/src/pages/product_app/router/user-order-router.js'
import { EventBus } from 'public/src/pages/common/event-bus.js'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'
import { prefetchOrdersListPageData } from 'public/src/pages/user/child_pages/orders/common/utils.js'

/* ------ components ------ */
import CommonNotice from 'public/src/pages/common/commonNotice/index.vue'
import MeHeader from './components/MeHeader.vue'
import MainSection from './components/MainSection.vue'
import MyOrdersSection from './components/MyOrdersSection.vue'
import MoreServicesSection from './components/MoreServicesSection.vue'
import WishAndRecentTabs from './components/WishAndRecentTabs.vue'

const { langPath, GB_cssRight } = gbCommonInfo
const analysisModule = new Analysis()
// 底部趋势频道内容体依赖 public/src/services/eventCenter/analysis/config/pages/dashboard/index.js
daEventCenter.addSubscriber({ modulecode: '1-16-1' })
daEventCenter.addSubscriber({ modulecode: '1-25-11' })
daEventCenter.addSubscriber({ modulecode: '1-25-12' })

export default defineComponent({
  name: 'UserIndex',
  components: {
    FooterRcmdList: defineAsyncComponent(() =>
      import(
        /* @vite-ignore */ 'public/src/pages/user/child_pages/dashboard/components/FooterRcmdList.vue'
      )),
    ShoppingGuide: defineAsyncComponent(() =>
      import(
        /* @vite-ignore */ 'public/src/pages/common/oneClickPay/components/shopping_guide/ShoppingGuide.vue'
      )),
    RiskDialog: defineAsyncComponent(() =>
      import(
        /* @vite-ignore */ 'public/src/pages/user_index/components/RiskDialog.vue'
      )),
  },
  data() {
    return {
      GB_cssRight,
      routeInfo: {},
    }
  },
  beforeRouteLeave(to, from, next) {
    this.beforeRouteLeaveSetup(to)
    next()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routeInfo = { to, from }
    })
  },
})
</script>

<script setup>
const instance = getCurrentInstance()
const rootInstance = instance?.proxy?.$root
const routerInstance = instance?.proxy?.$router
const routeInstance = instance?.proxy?.$route
const refsInstance = instance?.proxy?.$refs

const pageData = ref(null)
let clientAbt = {} // 数据太大，不适合做响应式

const abt = ref({})

const baseInfo = computed(() => pageData.value?.baseInfo)
const baseOtherInfo = computed(() => pageData.value?.baseOtherInfo)
const language = computed(() => pageData.value?.language)
const rootState = useMapState(['statusType'], 'root')
const storeState = useMapState([
  'routerUrl',
  'isLogin',
  // 'isRouterLeaveFlag',
  'dialogName',
  'isRisk',
])

const { setStoreState } = useMapMutations(['setStoreState'])
const { assignRootState } = useMapMutations(['assignRootState'], 'root')
const isPageDataReady = ref(false)
const showRefreshLoading = ref(false)

const {
  trendFlowData,
  fetchTrendFlowData,
  sheinClubInfo,
  abtSource,
  firstTrendFlowPending,
  resetTrendFlow,
} = useTrendFlow()

const {
  getFooterRcmdListCccConfig,
  footerRcmdCccConfig,
  footerRcmdListResolveStatus,
} = initFooterRcmdListModule()

// 添加ccc ready事件检查是否有推荐
const onCccReady = val => {
  footerRcmdListResolveStatus.value = val
    ? FooterRcmdListResolveStatus.HAS_RCMD_LIST
    : FooterRcmdListResolveStatus.NO_RCMD_LIST
}

const { newsBarInfo, getNewsListCccConfig } = initNewsListModule()

/* ------ computed ------ */
const isLogin = computed(() => storeState.isLogin.value)
const userInfo = computed(() => storeState.userInfo || {})
const memberId = computed(() => userInfo.value.memberId || '')
const getPersonalCenterEntranceInfo = computed(
  () => baseInfo.value?.personalCenterEntranceInfo || {},
)
const getUserLevelInfo = computed(() => baseInfo.value?.userLevelInfo || {})
const userAssetsBottomTipsInfo = computed(
  () => baseInfo.value?.expirePointCouponInfo || {},
)
const getPersonalInfo = computed(() => baseInfo.value?.personalInfo || {})
const getDynamicService = computed(() => baseInfo.value?.dynamicService || {})
const isShowSurveyPoints = computed(() => {
  if (!memberId.value) return false
  const hadClick = window.localStorage.getItem(`uid_${memberId.value}`)
  return hadClick
    ? new Date().getTime() - Number(hadClick) > 24 * 3600 * 1000
    : true
})
const isShowBg = computed(() => {
  const { MemberEntranceControl } = abt.value ?? {}
  if (!MemberEntranceControl) return false
  const hasV3Style =
    !!getPersonalCenterEntranceInfo.value?.member_card_list.find(
      n => n.cardStyle === 'v3',
    )
  return MemberEntranceControl !== 'None' && hasV3Style
})

provide('USER_CENTER_CONTEXT', {
  getClientAbt: () => clientAbt || {},
  pushLoginRoute: params => pushLoginRoute(params),
  getIsPageDataReady: () => isPageDataReady.value,
  getABTInfo: () => baseInfo.value?.abtInfo || {},
})

const { triggerPageAnalysis } = useUserCenterAnalysis({
  isLogin,
  getPersonalCenterEntranceInfo,
  getUserLevelInfo,
})

const initUserInfo = async username => {
  const isLogin = isLoginFn()
  let storeState
  if (isLogin) {
    const memberId = UserInfoManager.get({
      key: 'memberId',
      actionType: 'init',
    })
    const userEmail = await UserInfoManager.get({
      key: 'pwa_user_email',
      actionType: 'initUserInfo',
    })
    const displayName = username || userEmail || ''
    storeState = {
      isLogin,
      userInfo: {
        username: displayName,
        memberId,
      },
    }
  } else {
    storeState = {
      isLogin,
      userInfo: null,
    }
  }
  setStoreState(storeState)
}

const {
  isCommonNoticeShow,
  commonNotifyContent,
  initCommonNotify,
  onCloseCccNotify,
} = initCommonNotifyModule()

const showMoreServiceItemReminder = item => {
  const isLogin = isLoginFn()
  if (!isLogin) return false // 未登录不展示红点
  // 状态不存在才展示
  return item.iconAttributes?.isShow && !getRedDotStatus(item.type)
}

const onClickFooterUserIndexEntry = () => {
  if (!routeInstance.path.includes('user/index')) {
    return
  }
  if (abtSource.value.pwameRecommend?.p?.PageMeTabRefresh !== 'On') {
    resetTrendFlow()
    return
  }
  if (showRefreshLoading.value) {
    return
  }
  nextTick(() => {
    window.scrollTo(0, 0)
  })
  showRefreshLoading.value = true
  onDataInit({ waitFooterRcmd: 1 }).finally(() => {
    showRefreshLoading.value = false
    triggerPageAnalysis({
      needPV: false,
      needExpose: true,
    })
  })
  resetTrendFlow()
}

const showAfterMounted = ref(false)

onMounted(() => {
  window.appEventCenter?.on(
    'clickFooterUserIndexEntry',
    onClickFooterUserIndexEntry,
  )

  // 融合接口依赖于多语言，首次初始化信息流hook迁移至下方的watch
  // nextTick(() => {
  //   resetTrendFlow()
  // })

  requestIdleCallback(() => {
    EventBus.on('init-userIndex-userData', data => {
      setStoreState({
        routerUrl: routeInstance.path,
      })
      loginSucRedirection(data)
    })
    clear301cache() // 清除停服期间部分被301重定向的缓存链接

    // @perf 延迟组件执行
    showAfterMounted.value = true

    setTimeout(() => {
      userOrdersListRouteComponent()()
    }, 2000)
  })
})

// 商卡依赖于language，需要等待footerRcmdCccConfig，只需要监听一次即可
const unWatch = watch(() => footerRcmdCccConfig.value, val => {
  if (val) {
    resetTrendFlow(val.language?.itemLanguage ?? {})
    unWatch()
  }
}) 

// 业务埋点监控
const setMonitor = data => {
  if (!data) return
  metricPageSuccess(
    {
      page: 'page_user_index',
      status:
        data?.apiInfo?.firstPartInfo?.code == 0 &&
        data?.apiInfo?.secondPartInfo?.code == 0
          ? '1'
          : '0',
    },
    undefined,
    {
      firstPartInfoCode: data?.apiInfo?.firstPartInfo?.code,
      secondPartInfoCode: data?.apiInfo?.secondPartInfo?.code,
      firstPartInfoMsg: data?.apiInfo?.firstPartInfo?.msg,
      secondPartInfoMsg: data?.apiInfo?.secondPartInfo?.msg,
      original_url: window?.location?.href,
    },
  )
}

const processPageData = async () => {
  const { abtInfo, ...data } = (await handleFetchUserIndexApiCache()) ?? {}
  pageData.value = data
  clientAbt = abtInfo || {}
  abt.value = pageData.value?.baseInfo?.abtInfo
  // 添加监控
  setMonitor(data)
}

/* methods */
// 获取所有的接口 + 数据初始化
const onDataInit = async (params = {}) => {
  markPoint({ eventName: 'waitPageData', measureFrom: 'routeChangeEnd' })

  const { waitFooterRcmd } = Object.assign({ waitFooterRcmd: 0 }, params)
  isPageDataReady.value = false
  await processPageData()
  isPageDataReady.value = true
  getNewsListCccConfig()
  if (waitFooterRcmd) {
    await getFooterRcmdListCccConfig()
  } else {
    getFooterRcmdListCccConfig()
  }

  // 初始化用户信息
  await initUserInfo(getPersonalInfo.value?.nickname)

  await nextTick()
  const { notifyService } = baseInfo.value || {}

  initCommonNotify(notifyService)
  // 是否风控
  const isRisk = ['change_pwd_blasting', 'change_pwd_leak'].includes(
    getPersonalInfo.value?.riskInfo?.changePwd?.name,
  )

  const { site_from } = getUserLevelInfo.value
  // init store
  setStoreState({
    // isRouterLeaveFlag: false,
    isRisk,
    SiteUID: site_from,
  })
  rootInstance?.removeSkeleton()
  document.querySelector('.c-skeleton')?.classList.add('mshe-hide')

  markPoint({ eventName: 'pageDataReady', measureFrom: 'waitPageData' })
}

onBeforeMount(async () => {
  await onDataInit()
  triggerPageAnalysis({
    needPV: true,
    needExpose: true,
  })
})

onActivated(async () => {
  nextTick(() => {
    window?.scrollTo(0, 1)
    const topIconEl = document.querySelector('.scroll-top__icon')
    if(topIconEl){
      topIconEl.style.bottom = `calc(var(--scoll-to-top-bottom) - 0.3rem)`
    }
  })
  if (isPageDataReady.value) {
    // 返回到个人中心页时，不重置数据
    if (window?.appRouteExtData?.direction !== -1) {
      await onDataInit()
    }
    triggerPageAnalysis({
      needPV: true,
      needExpose: true,
    })
  }
})

onDeactivated( () => {
  const topIconEl = document.querySelector('.scroll-top__icon')
  if(topIconEl){
    topIconEl.style.bottom = `calc(var(--scoll-to-top-bottom))`
  }
})


const sendFn = page => {
  const {
    wallet_amount = 0,
    wallet_currency,
    coupons,
    point,
  } = getPersonalInfo.value
  if (typeof analysisModule.saObj !== 'undefined') {
    switch (page) {
      case JUMP_URL.USER_ORDERS_LIST:
        // analysisModule.saObj.clickOrders()
        break
      case JUMP_URL.USER_COUPON:
        analysisModule.saObj.clickMyCoupons({
          coupon_num: parseFloat(coupons) || 0,
        })
        break
      case JUMP_URL.USER_GIFT_CARD:
        analysisModule.saObj.clickGiftcard({ scene: 'me' })
        break
      case JUMP_URL.USER_ADDRESSBOOK:
        analysisModule.saObj.clickMyAddress()
        break
      case JUMP_URL.USER_WALLET: {
        analysisModule.saObj.clickMyWallet({
          wallet_num:
            wallet_amount && parseFloat(wallet_amount) != 0
              ? `${wallet_currency}_${wallet_amount}`
              : null,
        })
        break
      }
      case JUMP_URL.USER_SETTING:
        analysisModule.saObj.clickSettings()
        break
      case JUMP_URL.USER_POINTS:
        analysisModule.saObj.clickPoints({
          points_num: parseFloat(point) || 0,
        })
        break
      default:
        break
    }
  }
}

// 带弹出登录的路由跳转
const pushLoginRoute = ({ target, bi }) => {
  const isLogin = isLoginFn()
  const isSpa = !target.startsWith('http')
  const routerUrl = isSpa ? langPath + target : target
  if (isLogin) {
    isSpa ? routerInstance.push(routerUrl) : (window.location.href = routerUrl)
  } else {
    setStoreState({
      routerUrl,
    })
    SHEIN_LOGIN.show({
      show: true,
      bi,
      cb: loginSucRedirection,
    })
  }
}

const routerPush = ({
  target,
  scence = '',
  jumpIgnoreLogin = false,
  orderStatusType,
  noReportEvent = false,
}) => {
  if (
    storeState.isRisk.value &&
    !RISK_PATH_WHITE_LIST.some(val => target.indexOf(val) > -1)
  ) {
    openRiskDrawer()
    return
  }
  const _isLogin = isLoginFn()
  // 用户未读的问卷数量
  const questionnaireCount = getPersonalInfo.value?.questionnaireCount || 0
  setStoreState({
    routerUrl: target.startsWith('http') ? target : langPath + target,
  })
  if (!noReportEvent) {
    sendFn(target)
  }

  if (!['', null, undefined].includes(orderStatusType)) {
    // 跳转 order tab
    assignRootState({
      statusType: orderStatusType,
    })
  }

  if (_isLogin && questionnaireCount > 0 && isShowSurveyPoints.value) {
    window.localStorage.setItem(`uid_${memberId.value}`, new Date().getTime())
  }

  if (target.startsWith(JUMP_URL.AFFILIATE)) {
    window.location.href = `${location.origin}${langPath}${target}`
    return
  }

  if (_isLogin || jumpIgnoreLogin || [
    JUMP_URL.USER_SETTING,
    JUMP_URL.USER_SUPPORT,
    JUMP_URL.SHEINX,
    `${JUMP_URL.PRODUCT_ARTICLE}/1212`,
    JUMP_URL.USER_GIFT_CARD,
  ].includes(target)) {
    if (target.includes(JUMP_URL.EXCHANGE) || target.includes(JUMP_URL.TRIAL_USER_FREE_TRAIL)) {
      // ME页面的二手入口的地址，改为配置在中间层的阿波罗，需要支持到站点维度配置，本次会新增法国
      window.location.href = target
    } else {
      // 当http开头时，代表是链接跳转
      if (target?.startsWith('http')) {
        return (location.href = target)
      }
      markPoint('toNextPageClick', 'public')
      routerInstance.push(langPath + target)
    }
  } else {
    SHEIN_LOGIN.show({
      show: true,
      from: 'other',
      ga: 'account',
      bi: scence || 'other',
      cb: loginSucRedirection,
    })
  }
}

const clear301cache = () => {
  try {
    if (getQueryString({ key: 'countdown' }) > 0) {
      let _urlArr = [
        location.origin + langPath,
        location.origin + langPath + '/category',
        location.origin + langPath + '/user/message',
        location.origin + langPath + '/cart',
        location.origin + langPath + '/user/orders/list',
      ]
      if (document.referrer) _urlArr.push(document.referrer)
      _urlArr.forEach(url => {
        fetch(url, {
          method: 'GET',
          headers: { pragma: 'no-cache', 'cache-control': 'no-cache' },
        }).then(() => console.log(url + ' REQ SUCCESS'))
      })
    }
  } catch (e) {
    console.log(e)
  }
}

const openRiskDrawer = () => {
  refsInstance?.riskDialog?.riskDrawerUpdate()
}

const loginSucRedirection = async opts => {
  setStoreState({
    dialogName: 'default',
  })
  if (opts && opts.info && opts.info.member) {
    const user = opts.info.member
    initUserInfo(user?.nickname)
    if (user.isRegister) {
      await onDataInit()
      triggerPageAnalysis({
        needPV: true,
        needExpose: true,
      })
    }
    appEventCenter.emit('initUserInfo')
    // 这里又监听一个 emit 事件
    appEventCenter.emit('checkBlackListUser', isDanger => {
      if (isDanger) return
      const routerUrl = storeState.routerUrl.value || ''
      const isUserCenterPage = routerUrl.indexOf(JUMP_URL.USER_INDEX) > -1
      if (isUserCenterPage) {
        if (!user.isRegister) {
          window.location.href = routerUrl
        }
      } else if (routerUrl.startsWith('http')) {
        window.location.href = routerUrl
      } else {
        routerInstance.push(routerUrl)
      }
    })
  }
}

const changeUserRisk = isRisk => setStoreState({ isRisk })

defineExpose({
  beforeRouteLeaveSetup: to => {
    // 延时避免组件重复渲染影响其他页面的跳转时间
    // window.setTimeout(() => {
    //   setStoreState({
    //     isRouterLeaveFlag: true
    //   })
    // }, 500)

    // 订单性能优化代码
    if (isLoginFn() && to.path.includes(JUMP_URL.USER_ORDERS_LIST)) {
      to.meta.orderListPrefetch = prefetchOrdersListPageData({
        statusType: rootState.statusType.value,
      })
    }
  },
})

const showDetailEvt = () => {
  daEventCenter.triggerNotice({
    daId: '1-25-11-12',
  })
}
</script>
<style lang="less" scoped>
.c-index-me {
  background-color: #f6f6f6;
  padding-bottom: 50px;
  // &_bg {
  //   position: relative;
  //   &::after {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     content: '';
  //     width: 100%;
  //     background-image: var(--config-background-img);
  //     background-size: 100% auto;
  //     background-repeat: no-repeat;
  //     pointer-events: none;
  //     height: 5rem /* rw: 4.2rem */;
  //   }
  // }
  &.c-index-me_bg {
    background: url(https://img.ltwebstatic.com/v4/p/ccc/2025/03/13/e6/174184859304832ad0433b25f0cb34d1d4e3396539.webp)
      no-repeat;
    background-size: contain;
    :deep(.index-me-header) {
      background-color: transparent;
    }
    :deep(.panel-wrap) {
      background: transparent;
    }
  }
  .me-scroll-wrap {
    margin-bottom: 0.906rem;
    transform: translate3d(0, 0, 9px);
  }

  .common-notice-wrap {
      position: relative;
      z-index: @zindex-hack;
      margin:0 0.16rem;
      border-radius: 4px;
      overflow: hidden;
      :deep(.ccc-notice-alert) {
       padding: 0.16rem 0.213333rem;
      }
      :deep(.sui-alert__flex-description){
        padding-left: 0.48rem;
      }
      :deep(.sui-alert__flex-icon) {
        left: 0.16rem;
      }
      :deep(.sui-icons) {
        width: 16px;
        color: #767676;
      }
    }

  .advertise {
    border-bottom: 0.26666667rem solid #f6f6f6;
  }

  /deep/ .user-page__choice-store-card {
    margin-bottom: .16rem;
  }
}
</style>

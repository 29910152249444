<template>
  <NewUserCoupons
    v-if="styleType === CccComponentStyleType.NEW_USER_COUPON && !!newUserBenefits.rightInfo?.couponList?.length"
    :rights-info="newUserBenefits.rightInfo"
    :href-info="newUserBenefits.webJumpRelated"
  />
  <SingleBanner
    v-else-if="CccComponentStyleType.BANNER.includes(styleType)"
    :rights-info="newUserBenefits.rightInfo"
    :ug-activity-type="ugActivityType"
    :href-info="newUserBenefits.webJumpRelated"
  />
  <DeliverGoods
    v-else-if="styleType === CccComponentStyleType.DELIVER_GOODS && !!rightsInfo.goods?.length"
    :rights-info="newUserBenefits.rightInfo"
    :href-info="newUserBenefits.webJumpRelated"
  />
</template>

<script setup name="NewUserBenefitModule">
import { computed } from 'vue'
import NewUserCoupons from './NewUserCoupons.vue'
import SingleBanner from './SingleBanner.vue'
import DeliverGoods from './DeliverGoods.vue'
import { CccComponentStyleType } from 'public/src/pages/user_index/hooks/new-user-benefits'

const props = defineProps({
  newUserBenefits: {
    type: Object,
    default: null,
  },
})

const styleType = computed(() => props.newUserBenefits?.hitNewCustomerRightType)
const ugActivityType = computed(() => +props.newUserBenefits?.realNewCustomerRightType || 0)
const rightsInfo = computed(() => props.newUserBenefits?.rightInfo || {})
</script>

<template>
  <div class="rights-fusion-floor__wrapper">
    <div
      v-for="(card, i) in cardList"
      :key="i"
      class="rights-fusion-floor__item"
    >
      <MemberCard
        v-if="card.cardType === UserRightsCardType.MEMBER_CARD"
        class="rights-fusion-floor__prime"
        :computed-styles="getItemStyle(card)"
        context="fusion-floor"
        :card-info="card"
        :payback-info="rightsInfo?.payback_info"
        :renew-popup-info="rightsInfo?.renew_pop_up_info"
        :renew-popup-info-v2="rightsInfo?.renew_pop_up_info_v2"
        :renew-popup-info-v3="rightsInfo?.renew_pop_up_info_v3"
        :free-gift-popup-info="rightsInfo?.freeGiftPopupInfo"
        @dialogOpened="openCallback"
      />
      <NewSuperSavePanel
        v-if="card.cardType === UserRightsCardType.SUPER_SAVE"
        v-expose="{
          id: '1-25-11-29',
          data: superSaveReportParams,
        }"
        :card-info="card"
        :computed-styles="getItemStyle(card)"
        :report-params="superSaveReportParams"
      />
    </div>
  </div>
</template>

<script setup name="UserRightsFusionFloorNew">
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { computed, defineEmits } from 'vue'
import NewSuperSavePanel from './NewSuperSavePanel.vue'
import MemberCard from './MemberCard.vue'
// import { getCouponStyles } from 'public/src/pages/user_index/hooks/user-rights-fusion'
import { UserRightsCardType } from 'public/src/pages/user_index/service/consts'
import { IMGS } from '../../util/const'

const props = defineProps({
  rightsInfo: {
    type: Object,
    default: () => ({}),
  },
  primePanelBindData: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits(['dialogOpened'])

const cardList = computed(() => props.rightsInfo?.member_card_list || [])
const superSaveCardData = computed(() => cardList.value.find(card => card.cardType === UserRightsCardType.SUPER_SAVE))

const superSaveReportParams = computed(() => {
  const { isSuperSaveMember, buriedObject = [] } = superSaveCardData.value || {}
  const position = cardList.value.findIndex(card => card.cardType === UserRightsCardType.SUPER_SAVE)
  const showMemberCard = cardList.value.some(card => card.cardType === UserRightsCardType.MEMBER_CARD)
  const { abtest, has_unpaid_order } = buriedObject[0]?.defaultParams || {}
  return {
    is_club_show: showMemberCard ? 1 : 0,
    saver_status: isSuperSaveMember ? 'valid' : 'null',
    position: position > -1 ? position + 1 : '',
    abtest,
    has_unpaid_order
  }
})

const getCardStyle = (card) => {
  const cardNum = cardList.value.length
  const cardPriority = card.cardPriority
  // 卡样式:p1主卡,p2副卡,p3卡平分
  const WIDTH = {
    p1: '6.50667rem',
    p2: '3.06667rem',
    p3: '4.7733rem',
  }

  if(cardNum == 1) {
    return {
      width: '9.68rem'
    }
  }

  if(cardNum > 1) {
    return {
      width: WIDTH[cardPriority]
    }
  }
  
  return {
    width: '9.68rem'
  }
}

const getBtnStyle = (card) => {
  const buttonColor = card.buttonColor
  let res = {}
  const CARD_BTN = {
    1: {
      background: '#FAF0EC',
      color: '#C96E3F'
    },
    2: {
      background: '#FEE9EE',
      color: '#F82854'
    }
  }

  if(buttonColor === 'BTN_SameColor') {
    res = {
      background: '#FEEFEB',
      color: '#FA6338'
    }
  } else {
    res = CARD_BTN[card.cardType]
  }
  return res
}

const getCouponStyle = (card) => {
  const cardNum = cardList.value.length
  const cardPriority = card.cardPriority
  // 会员卡片类型：1-付费会员，2-超省卡
  const cardType = card.cardType
  // 1: 单卡  2: 双卡; 卡样式:p1主卡,p2副卡,p3卡平分
  let res = {
    1: {
      1: {
        width: '6.4rem',
      },
      2: {
        width: '2.9867rem',
      },
      3: {
        width: '3.0133rem',
      },
    },
    2: {
      p1: {
        1: {
          width: '5.3333rem',
        },
        2: {
          width: '2.9867rem',
        },
        3: {
          width: '1.9467rem',
        },
      },
      p2: {
        1: {
          width: '2.64rem',
        },
      },
      p3: {
        1: {
          width: '4.2667rem',
        },
        2: {
          width: '2.1067rem',
        },
      },
    }
  }

  const getP2Coupon = (card) => {
    let list = []
    // let isShippingCoupon = false
    let isMulti = false
    const res = {
      // 单张 1 邮费
      0: {
        0: IMGS.couponSingle,
        1: IMGS.couponSingle,
      },
      // 多张
      1: {
        0: IMGS.couponBag,
        1: IMGS.couponBag
      }, 
    }
    if(cardType == 2 && card.couponInfoList.length) {
      list = card.couponInfoList
      // isShippingCoupon = list[0].type == 'freeShipping' ? 1 : 0
      isMulti = 1 
    }

    if(cardType == 1 && card.benefit.length) {
      list = card.benefit
      // isShippingCoupon = list[0].couponType == 1
      isMulti = list[0].type == 'freeGift' ? 0 : (list.length > 1 ? 1 : 0)
    }

    // console.log(res[isMulti])
    
    return res[isMulti]

  }

  let couponImg = {
    1: {
      1: {
        0: IMGS.couponBig0,
        1: IMGS.couponBig1
      },
      2: {
        0: IMGS.couponMedium0,
        1: IMGS.couponMedium1,
      },
      3: {
        0: IMGS.couponSmall0,
        1: IMGS.couponSmall1
      },
    },
    2: {
      p1: {
        1: {
          0: IMGS.couponBig0,
          1: IMGS.couponBig1
        },
        2: {
          0: IMGS.couponMedium0,
          1: IMGS.couponMedium1,
        },
        3: {
          0: IMGS.couponSmall0,
          1: IMGS.couponSmall1
        },
      },
      p2: {
        1: getP2Coupon(card),
      },
      p3: {
        1: {
          0: IMGS.pairMedium0,
          1: IMGS.pairMedium1
        },
        2: {
          0: IMGS.pairSmall0,
          1: IMGS.pairSmall1 
        },
      },
    }
  }

  return cardNum > 1 ? { style: res[cardNum][cardPriority], couponImg: couponImg[cardNum][cardPriority] } : { style: res[cardNum], couponImg: couponImg[cardNum] }
}

const getItemStyle = (card) => {
  const cardStyle = getCardStyle(card)
  const btnStyle = getBtnStyle(card)
  const couponStyle = getCouponStyle(card)

  const expireTagStyle = card?.status == 2 ? {
    background: '#F6F6F6',
    color: '#767676'
  } : {}
  
  return {
    cardStyle,
    btnStyle,
    expireTagStyle,
    couponStyle
  }
}

const openCallback = (dialogType) => {
  emits('dialogOpened', dialogType)
}
</script>

<style lang="less" scoped>
.rights-fusion-floor {
  &__wrapper {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: .2133rem .16rem .16rem .16rem;
    margin-top: -7/37.5rem;
    &::-webkit-scrollbar {
      height: 0;
    }
  }
  &__item:not(:first-child) {
    margin-left: .1067rem;
  }
  &__prime {
    height: 100%;
    :deep(.atmosphere-bg) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .paid-vip-content {
        flex: 1;
      }
    }
    :deep(.paid-vip-ctn) {
      height: 80/37.5rem;
      margin: 0;
    }
  }
}
</style>


<template>
  <s-dialog
    class="confirmModal"
    :visible="!!modalInfo"
    :show-close="false"
    :append-to-body="true"
    :close-on-click-modal="true"
  >
    <p
      class="mshe-text-center"
      tabindex="0"
    >
      {{ modalInfo?.info }}
    </p>
    <template #footer>
      <s-button-group hor>
        <s-button-group-item
          :type="['primary', 'H72PX']"
          @click="handleConfirmModalOk"
        >
          {{ modalInfo?.okText || language.SHEIN_KEY_PWA_15312 }}
        </s-button-group-item>
        <s-button-group-item
          :type="['H72PX']"
          @click="closeConfirmDialog"
        >
          {{ language.SHEIN_KEY_PWA_16092 }}
        </s-button-group-item>
      </s-button-group>
    </template>
  </s-dialog>
</template>
<script>
import { defineComponent } from 'vue'
const { language } = gbCommonInfo
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'

export default defineComponent({
  components: {
    SButtonGroup,
    SButtonGroupItem,
    SDialog,
  },
  props: {
    modalInfo: {
      type: Object,
      default: null,
    }
  },
  data: function () {
    return {
      language
    }
  },
  methods: {
    handleConfirmModalOk() {
      this.modalInfo?.ok()
      this.closeConfirmDialog()
    },
    closeConfirmDialog() {
      this.$emit('close')
    }
  },
})
</script>

<template>
  <div class="tips-list not-fsp-element">
    <div class="tips-arrow">
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 1 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 2 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 3 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 4 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
    </div>
    <SAlert
      key="coupon"
      :show-icon="false"
      type="info"
      :closeable="false"
      class="preference-remind remind-tips"
      @click="onClick"
    >
      <div
        tabindex="0"
        class="preference-text"
        v-html="tipsContentHtml"
      >
      </div>
      <template #operation>
        <i
          class="iconfont icon-close icon-jumpable"
          @click.stop="onClose"
        ></i>
      </template>
    </SAlert>
  </div>
</template>

<script setup name="AlertTips">
import { ref, computed } from 'vue'
import { SAlert } from '@shein-aidc/sui-alert/mobile'

const props = defineProps({
  arrowPosition: {
    type: Number,
    default: 0,
  },
  tipsContent: {
    type: String,
    default: '',
  },
})

const lang = ref(gbCommonInfo?.lang)
const tipsContentHtml = computed(() => {
  return props.tipsContent.replace(/(\d+)/g, '<span class="number">$1</span>')
})
const emits = defineEmits([
  'on-close',
  'on-click',
])
const onClose = () => emits('on-close')
const onClick = () => emits('on-click')
</script>

<style lang="less" scoped>
.tips-list {
  padding-bottom: 0.16rem;
  margin-top: -24/75rem;
  background: #FFF;
  :deep(.sui-alert__flex) {
    padding: 0.2133rem 0.16rem;
  }
  :deep(.icons-close) {
    width: 16px;
    color: #767676;
  }
}

.tips-arrow {
  display: flex;

  .arrow {
    flex: 1;
    justify-content: center;
    display: flex;
    align-items: center;

    .arrow-icon {
      border: 12/75rem solid transparent;
      border-bottom-color: #fff1d5;
      width: 12/75rem;
      height: 12/75rem;
      display: inline-block;
    }
  }
}

.preference-remind {
  .preference-text {
    .padding-r(24/75rem);
    :deep(.number) {
      color: #FA6338;
    }
  }
  [class*='iconfont'] {
    color: @sui_color_gray_light1;
  }
}

.remind-tips {
  margin: 0 12/75rem;
  background: @sui_color_prompt_warning;
  border-radius: 2px;
  :deep(.iconfont) {
    font-size: 24/75rem;
  }
  :deep(.sui-alert_icon-info){
    width: 32/75rem;
    color: #767676;
  }
}
</style>


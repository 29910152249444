<template>
  <div 
    class="fusion-super-save__panel"
    @click.stop="onSuperSavePanelClick"
  >
    <Card
      :cornerBgImg="IMGS.saverIcon"
      :custom-style="computedStyles?.cardStyle"
      :text="cardInfo?.cardPriority == 'p2' ? '' : cardInfo?.mainText"
    >
      <!-- subtitle -->
      <div class="fusion-super-save__subtitle">
        <Subtitle :title="cardInfo?.subText" />
      </div> 
      <!-- benefit -->
      <div class="fusion-super-save__benefit">
        <div
          v-for="(item, index) in couponInfoList"
          :key="index"
          class="fusion-super-save__benefit_item"
        >
          <Benefit
            :bgImg="computedStyles.couponStyle.couponImg[couponInfoList.length][item.couponType]"
            :cornerText="item?.cornerText"
            :benefitVal="item?.discountTip"
            :desc="item?.thresholdTip"
            :seconds="item?.expireAt"
            :customStyle="computedStyles.couponStyle.style[couponInfoList.length]"
          />
        </div>
      </div> 
      <!-- btn -->
      <div class="fusion-super-save__btn">
        <Btn
          :text="cardInfo?.buttonTip"
          :custom-style="computedStyles?.btnStyle"
        />
      </div> 
    </Card>
  </div>
</template>

<script setup name="NewSuperSavePanel">
import { ref, computed, getCurrentInstance } from 'vue'
import Card from './Card.vue'
// import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { SuperSaveCouponStatus } from 'public/src/pages/user/child_pages/dashboard/service/constants'
import { daEventCenter } from 'public/src/services/eventCenter'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils'
import { COUNT_MAP_BY_STYLE, IMGS } from 'public/src/pages/user_index/util/const'
import Subtitle from './Subtitle.vue'
import Benefit from './Benefit.vue'
import Btn from './Btn.vue'

const props = defineProps({
  cardInfo: {
    type: Object,
    default: () => ({}),
  },
  computedStyles: {
    type: Object,
    default: () => ({}),
  },
  reportParams: {
    type: Object,
    default: () => ({}),
  },
})

const vm = getCurrentInstance()
const GB_cssRight = ref(gbCommonInfo?.GB_cssRight)
const getCountDownSeconds = (coupon) => {
  if (coupon?.couponStatus === SuperSaveCouponStatus.TO_USE && coupon?.expireAt) {
    const currentTime = Math.floor(Date.now() / 1000)
    return coupon.expireAt - currentTime
  }
}


const couponInfoList = computed(() => props.cardInfo?.couponInfoList?.slice(0, COUNT_MAP_BY_STYLE[props.cardInfo?.cardPriority]))


const onSuperSavePanelClick = () => {
  let url = props.cardInfo?.url
  let appRoute = props.cardInfo?.appRoute
  if(appRoute) {
    url = appRoute
  }
  setTimeout(() => {
    const { langPath } = gbCommonInfo
    const isLogin = isLoginFn()
    const toDetailPage = () => {
      const jumpUrl = url || '/user/sheinsaver?productsource=me'
      vm.proxy.$router.push(`${langPath}${jumpUrl[0] === '/' ? '' : '/'}${jumpUrl}`)
    }
    // toDetailPage()
    if (isLogin) {
      toDetailPage()
    } else {
      SHEIN_LOGIN.show({
        show: true,
        bi: 'saver',
        cb: () => {
          toDetailPage()
        }
      })
    }
  }, 300)
  daEventCenter.triggerNotice({
    daId: '1-25-11-30',
    bindData: props.reportParams,
  })
}
</script>

<style lang="less" scoped>
@import './style/common.less';
.fusion-super-save {

  &__subtitle {
    display: flex;
    align-items: flex-start;
    margin: .16rem 0 .1067rem 0;
    height: .37333rem;
  }

  &__benefit {
    .center();
    &_item:not(:last-child) {
      margin-right: .1067rem;
    }
  }

  &__btn {
    .center();
    margin-top: .1067rem;
  }
 
}
</style>

<template>
  <div
    class="rights-benefit"
    :style="{
      ...customStyle,
      padding: '0 0.0533rem',
      '--bg-img-url': `url(${bgImg})`,
    }"
  >
    <div
      class="rights-benefit__detail"
      :style="rightDesc ? { maxWidth: '50%' } : {}"
    >
      <div
        v-if="benefitVal"
        class="rights-benefit__val"
      >
        <TemplateText
          :customStyle="{
            fontWeight: 700,
            alignItems: 'baseline'
          }"
          :text="benefitVal"
        />
      </div>

      <div
        v-if="benefitImg"
        class="rights-benefit__detail_img"
        :style="{
          backgroundImage: `url(${benefitImg})`, 
          backgroundSize: '100% 100%'
        }"
      >
      </div>
      <span
        class="rights-benefit__desc"
        v-html="desc"
      ></span>
    </div>
    <div
      v-if="rightDesc"
      class="rights-benefit__right"
    >
      <TemplateText
        :customStyle="{
          fontWeight: 400,
          fontSize: '.32rem'
        }"
        :text="rightDesc"
      />
    </div>
    <!-- 右上角标 -->
    <span
      v-if="cornerText || (seconds && seconds < 72 * 3600)"
      class="rights-benefit__corner"
    >
      <span v-html="getCornerText()"></span>
      <CouponCountdown
        v-if="showCouponCountdown(seconds)"
        class="rights-benefit__coupon-countdown"
        :seconds="seconds"
      />
    </span>
    <slot></slot>
  </div>
</template>

<script setup name="benefit">
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { CountDown, template } from '@shein/common-function'

import CouponCountdown from './CouponCountdown.vue'
import TemplateText from './TemplateText.vue'

const props = defineProps({
  customStyle: {
    type: Object,
    default: () => {},
  },
  bgImg: {
    type: String,
    default: '',
  },
  benefitImg: {
    type: String,
    default: '',
  },
  desc: {
    type: String,
    default: '',
  },
  rightDesc: {
    type: String,
    default: '',
  },
  benefitVal: {
    type: String,
    default: '',
  },
  cornerText: {
    type: String,
    default: '',
  },
  countdownText: {
    type: String,
    default: '',
  },
  seconds: {
    type: Number,
    default: 0,
  },
})

const countDownInstance = ref(null)
const countDownObj = ref({})
const initCountdown = () => {
  const countdown = new CountDown({
    dayMode: false,
  })
  const { seconds } = props
  if(seconds && seconds <= 72 * 3600) {
    countdown.start({
      seconds,
      countFunc: (timeObj) => {
        countDownObj.value = timeObj
      },
    })
    countDownInstance.value = countdown
  }
  
}

onMounted(() => {
  initCountdown()
})

onUnmounted(() => {
  const countdown = countDownInstance.value
  if (countdown) {
    countdown.clear?.()
  }
})

watch(() => props.seconds, (secs) => {
  if(secs && secs <= 72 * 3600) {
    // 清除旧定时器
    const countdown = countDownInstance.value
    if (countdown) {
      countdown.clear?.()
    }
    initCountdown()
  }
})

const showCouponCountdown = coupon => {
  const countDownSeconds = coupon
  return countDownSeconds && !props.countdownText && countDownSeconds < 72 * 3600
}

const getCornerText = () => {
  if(props.seconds && props.seconds < 72 * 3600) {
    if(props.countdownText) {
      return template(` ${countDownObj.value.H || '00' }:${countDownObj.value.M || '00'}:${countDownObj.value.S || '00'}`, props.countdownText)
    }
    return ''
  }
  return props.cornerText
}

</script>
<style lang="less" scoped>
@import './style/common.less';
.rights-benefit {
  z-index: 1;
  position: relative;
  .center();
  width: 1.9467rem;
  height: 1.6rem;

  &__detail {
    width: 100%;
    .center();
    line-height: normal;
    display: flex;
    flex-direction: column;

    &_img {
      margin-bottom: .08rem;
      width: .5333rem;
      height: .5037rem;
    }
  }

  &__val {
    width: 100%;
    text-align: center;
    :deep(.rights-template) {
      text-align: center;
      display: inline-block;
      max-width: 100%;
      .single-line-overflow();
    }
  }

  &__right {
    max-width: 50%;
    margin-left: .2133rem;
    text-align: center;
    line-height: normal;
    .clamp2();
    word-break: break-word;
  }

  &__desc {
    .clamp2();

    color: #000;
    text-align: center;
    font-size: .24rem;
    font-weight: 400;
    line-height: normal;
  }

  &__corner {
    display: inline-block;
    max-width: 66.666%;
    line-height: normal;
    .single-line-overflow();
    border-radius: 0px .1067rem;
    background: #FAF0EC;
    padding: 1px .0533rem;

    color: #FA6338;
    font-size: .24rem;
    font-weight: 500;
    position: absolute;
    top: 0;
    right: 0;

  }
   &__coupon-countdown {
    display: inline-block;
    margin-left: .0533rem;
   }
}
.rights-benefit::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--bg-img-url); 
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
  transform: scaleX(1)/*rtl: scaleX(-1)*/;
}
</style>

<template>
  <div
    v-if="showAlert"
    class="ccc-notice-box"
  >
    <s-alert
      :key="key"
      class="ccc-notice-alert"
      :type="type"
      :closeable="closable"
      :show-icon="showIcon"
      :max-rows="3"
      :load-more-txt="loadingText"
      @onLoadMoreClick="onLoadMoreClick"
      @close="closeAlertEvt"
    >
      {{ message }}
    </s-alert>
    <ClientOnly>
      <s-dialog
        v-if="showText"
        v-model:visible="showText"
        :show-close="false"
        :close-on-click-modal="true"
        :immediately-render="true"
        :append-to-body="true"
        class="ccc-notice-dialog"
      >
        <div
          class="notice-ctn"
          v-html="message"
        ></div>
        <template #footer>
          <s-button
            :type="['primary']"
            :width="'100%'"
            @click="showText = !showText"
          >
            {{ language.SHEIN_KEY_PWA_15312 }}
          </s-button>
        </template>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script>
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { defineComponent, nextTick } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'

const { language = {} } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
export default defineComponent({
  name: 'CommonNotice',
  components: {
    ClientOnly,
    SAlert,
    SButton,
    SDialog,
  },
  emits: ['closeAlert', 'showDetailEvt'],
  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'info',
    },
    closable: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    rtlIconRoate: {
      type: Boolean,
      default: true
    },
    loadingText: {
      type: String,
      default: language.SHEIN_KEY_PWA_16133,
    }
  },
  data () {
    return {
      language,
      showAlert: false,
      showText: false
    }
  },
  computed: {
    key () {
      let timer = new Date().getTime()
      return `${timer}_notice`
    }
  },
  watch: {
    message (val) {
      this.showAlert = false
      nextTick(() => {
        this.showAlert = !!val
      })
    }
  },
  created () {
    this.showAlert = !!this.message
  },
  methods: {
    onLoadMoreClick () {
      this.showText = !this.showText
      this.$emit('showDetailEvt')
    },
    closeAlertEvt () {
      this.showAlert = false
      this.$emit('closeAlert')
    }
  },
})
</script>

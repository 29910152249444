<template>
  <div
    class="rights-btn"
    :style="{
      ...customStyle,
    }"
  >
    <span
      class="rights-btn__text"
      v-html="text"
    >

    </span>
    <slot></slot>
  </div>
</template>

<script setup name="btn">
import { ref, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  customStyle: {
    type: Object,
    default: () => {},
  },
  text: {
    type: String,
    default: '',
  }
})

onMounted(() => {
})



onUnmounted(() => {
})
</script>
<style lang="less" scoped>
@import './style/common.less';
.rights-btn {
  position: relative;
  width: 100%;
  .center();
  background: #FEEFEB;
  border-radius: .4267rem;
  color: #FA6338;
  padding: .1067rem .2133rem;
  text-align: center;
  font-size: .32rem;
  font-weight: 500;
  max-width: 6.08rem;

  &__text{
    .single-line-overflow();
  }
}
</style>

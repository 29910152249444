<template>
  <div
    v-if="countDownObj"
  >
    <!-- <template v-if="GB_cssRight">
      {{ countDownObj.S || '00' }}:{{ countDownObj.M || '00' }}:{{ countDownObj.H || '00' }}
    </template> -->
    <!-- <template v-else> -->
    {{ countDownObj.H || '00' }}:{{ countDownObj.M || '00' }}:{{ countDownObj.S || '00' }}
    <!-- </template> -->
  </div>
</template>

<script setup name="CouponCountdown">
import { ref, onMounted, onUnmounted } from 'vue'
import { CountDown } from '@shein/common-function'

const props = defineProps({
  seconds: {
    type: Number,
    default: 0,
  },
})

onMounted(() => {
  initCountdown()
})

const GB_cssRight = ref(gbCommonInfo?.GB_cssRight)
const countDownInstance = ref(null)
const countDownObj = ref({})
const initCountdown = () => {
  const countdown = new CountDown({
    dayMode: false,
  })
  const { seconds } = props
  countdown.start({
    seconds,
    countFunc: (timeObj) => {
      countDownObj.value = timeObj
    },
  })
  countDownInstance.value = countdown
}

onUnmounted(() => {
  const countdown = countDownInstance.value
  if (countdown) {
    countdown.clear?.()
  }
})
</script>

<template>
  <div
    class="unpaid-order__item" 
    @click.stop="outerClick"
  >
    <!-- up arrow -->
    <span
      :style="{ backgroundImage: `url(${IMGS.arrow})`, left: isOverFour ? '.3667rem' : '.6667rem' }"
      class="unpaid-order__arrow"
    >
    </span>
    <!-- left -->
    <div class="unpaid-order__item-left">
      <img
        class="item-left__img"
        :src="transformImg({ img: props.item?.goodImage || '' })"
      />

      <span
        v-if="props.item?.goodNum"
        class="item-left__num"
      >{{ props.item?.goodNum }}</span>
      <!-- style4老客样式 -->
      <div
        v-if="item?.benefitStyle == STYLE.style4"
        class="item-left__info"
      >
        <div class="item-left__incentive center-pos">
          <span
            :style="{ marginRight: '.1067rem' }"
            class="text-clamp2"
            v-html="item?.upTips"
          ></span>
          <IncentiveTag
            v-if="item?.freeShippingLabel && !isShowSaveMoneyTip"
            :text="item?.freeShippingLabel"
            :customStyle="STYLE_BENEFIT_TYPE[5]"
          />
          <div
            v-if="isShowSaveMoneyTip"
            class="item-left__saved-box"
          >
            <img 
              class="item-left__saved-icon"
              :src="USER_INDEX_LOCAL_IMG.downwardTrendIcon"
            />
            <ScrollingText 
              :text="item?.savedMoneyTip"
            />
          </div>
        </div>
      </div>

      <div
        v-if="item?.benefitStyle !== STYLE.style4"
        class="item-left__info"
      >
        <div
          v-if="item?.benefitStyle == STYLE.style1 || item?.benefitStyle == STYLE.style2"
          class="item-left__incentive center-pos"
        >
          <span
            :class="['item-left__pay-text', item?.benefitStyle == STYLE.style1 ? 'text-clamp2' : 'text-clamp1']"
            v-html="item?.upTips"
          ></span>
          <IncentiveTag
            :text="item?.benefitText"
            :customStyle="STYLE_BENEFIT_TYPE[item?.benefitType]"
          />
        </div>

        <div class="item-left__info-content" :style="{ flexDirection: item?.benefitStyle === STYLE.style3 ? 'column' : '' }">
          <div
            v-if="item?.benefitStyle === STYLE.style3"
            :style="{ marginRight: '.1067rem' }"
            class="item-left__info-total" 
            v-html="props.item?.upTips"
          ></div>
          <div
            v-if="isShowSaveMoneyTip"
            :style="{ margin: '.1067rem 0 0 0' }"
            class="item-left__saved-box"
          >
            <img 
              class="item-left__saved-icon"
              :src="USER_INDEX_LOCAL_IMG.downwardTrendIcon"
            />
            <ScrollingText 
              :text="props.item?.savedMoneyTip"
            />
          </div>
        </div>
        <div
          v-if="isShowFreeShipping"
          class="item-left__tip-box"
        >
          <img 
            :src="transformImg({ img: getTipsIcon })"
            class="item-left__tip-icon"
          />
          <div
            class="item-left__tip-text text-clamp2"
          >
            {{ props.item?.freeShippingLabel }}
          </div>
        </div>
      </div>
    </div>

    <!-- right -->
    <div class="unpaid-order__item-right center-pos">
      <s-button
        :type="['primary', 'H48PX']"
        @click.stop="payNowHandle"
      >
        <CornerTag
          v-if="getBtnBenefitText"
          :hasIcon="getBtnBenefitType == 5 ? true : false"
          :customStyle="STYLE_CORNER_BENEFIT_TYPE[getBtnBenefitType]"
          :footerStyle="STYLE_BENEFIT_FOOTER[getBtnBenefitType]"
          :text="getBtnBenefitText"
        >
          <img 
            v-if="getBtnBenefitType == 5"
            :src="transformImg({ img: getTipsIconWhite })"
            class="item-left__tip-icon"
          />
        </CornerTag>
        <div class="unpaid-order__right-text">
          <span
            id="unpaid-order__btn-text"
            class="unpaid-order__btn-text text-over"
          >
            {{ clickButton?.content || '' }}
          </span>
          <span
            id="unpaid-order__countdown"
            class="btn-countdown"
            v-html="countDownTipsNew"
          ></span>
        </div>
      </s-button>
    <!-- <div 
        :class="`unpaid-order__count-down ${timeDomClass}`"
      >
        <div class="unpaid-order__count-down-info">
          <i class="suiiconfont sui_icon_hourglass_12px_2"></i>
          <span v-html="countDownTips"></span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { SButton } from '@shein-aidc/sui-button/mobile'
// 未支付订单(新)
import { ref, onMounted, defineEmits, computed, watch, getCurrentInstance } from 'vue'
import { CountDown, transformImg } from '@shein/common-function'
import ScrollingText from './ScrollingText'
import IncentiveTag from './IncentiveTag'
import CornerTag from './CornerTag'
import { STYLE, STYLE_BENEFIT_FOOTER, STYLE_BENEFIT_TYPE, STYLE_CORNER_BENEFIT_TYPE, IMGS } from './constants'
import { USER_INDEX_LOCAL_IMG } from '../../../consts.js'
import { formatUrlQuery } from '../../../service/utils.js'
import { getBuriedData, getExtraData } from 'public/src/pages/user_index/service/utils.js'
const { langPath } = gbCommonInfo


const instance = getCurrentInstance()
const routerInstance = instance?.proxy?.$router

const countTimer = ref(new CountDown())
const timeDomClass = ref('')

const props = defineProps({
  item: { type: Object, default: () => {} },
  isOverFour: { type: Boolean, default: false },
  unpaidOrderActiveClass: { type: String, default: '' }
})

const emits = defineEmits(['countdownEnd'])


const setFontStyle = (id, id1, target) => {
  const saveTextNode = document.getElementById(id)
  const saveTextNode1 = document.getElementById(id1)
  if (!saveTextNode1) return
  if (saveTextNode1.innerText.length > 20 ||  saveTextNode1.clientWidth < saveTextNode1.scrollWidth) {
    // saveTextNode1.style.fontSize = target1
    saveTextNode.style.fontSize = target
    saveTextNode.style.height = '.2933rem'
    saveTextNode.style.lineHeight = '.2933rem'
    saveTextNode.style.marginBottom = '.1067rem'
  }
}

const handleTextStyle = () => {
  requestAnimationFrame(() => {
    setFontStyle('unpaid-order__countdown', 'unpaid-order__btn-text', '.24rem')
  })
}

onMounted(() => {
/* 未支付订单倒计时 */
  const { expireCountdown } = props.item
  if (expireCountdown) {
    countTimer.value.start({
      seconds: expireCountdown,
      endFunc: () => {
        // 倒计时结束
        emits('countdownEnd')
      }
    })
  }
  // 埋点
  daEventCenter.triggerNotice({
    daId: '1-25-11-1012',
    extraData: getExtraData(getBuriedData(props.item?.buriedObject || [], 1))
  })
  handleTextStyle()
})

const outerClick = () => {
  const { jumpUrl = '', jumpParams = {} } = props.item?.urlObject || {}
  const { billno } = jumpParams
  const urlQuery = formatUrlQuery({
    goBack: 1,
  })
  const reportInfo = (props.item?.buriedObject || []).find(item => item.buriedType === 2 && item.buriedKey === 'order_floor_other')

  daEventCenter.triggerNotice({
    daId: '1-25-11-1030',
    extraData: getExtraData(reportInfo?.defaultParams),
  })
  routerInstance.push(`${langPath}${jumpUrl}/${billno}?${urlQuery}`)
}

const payNowHandle = () => {
  const { jumpUrl = '', jumpParams = {} } = clickButton.value.urlObject
  const { billno, page_from, from_type, show_selected_pay_method } = jumpParams
  const urlQuery = formatUrlQuery({
    goBack: 1,
    show_error_guide_payment: show_selected_pay_method,
    from_type,
    page_from
  })
  const reportInfo = (props.item?.clickButton?.buriedObject || []).find(item => item.buriedType === 2 && item.buriedKey === 'order_floor_btn')
  daEventCenter.triggerNotice({
    daId: '1-25-11-1003',
    extraData: getExtraData(reportInfo?.defaultParams),
  })
  routerInstance.push(`${langPath}${jumpUrl}/${billno}?${urlQuery}`)
}

const clickButton = computed(() => props.item?.clickButton)


const countDownTipsNew = computed(() => {
  const { H, M, S } = countTimer.value?.timeObj || {}
  return `<span>${H}</span>:<span>${M}</span>:<span>${S}</span>`
})

const getTipsIcon = computed(() => {
  const labelType = props.item?.labelType || ''
  switch(+labelType) {
    case 1: // 包邮
      return USER_INDEX_LOCAL_IMG.freeShippingSolid
    case 2: // 快速运输标签
      return USER_INDEX_LOCAL_IMG.fastFreightSolid
    case 3: // 准时宝标签
      return USER_INDEX_LOCAL_IMG.completeSolid
    case 4: // 运输时效
      return USER_INDEX_LOCAL_IMG.transportationSolid
    case 5: // 免费退货标签
      return USER_INDEX_LOCAL_IMG.freeRefundSolid
    default:
      return ''
  }
})


const getTipsIconWhite = computed(() => {
  const labelType = props.item?.labelType || ''
  switch(+labelType) {
    case 1: // 包邮
      return USER_INDEX_LOCAL_IMG.freeShipping
    case 2: // 快速运输标签
      return USER_INDEX_LOCAL_IMG.fastFreight
    case 3: // 准时宝标签
      return USER_INDEX_LOCAL_IMG.complete
    case 4: // 运输时效
      return USER_INDEX_LOCAL_IMG.transportation
    case 5: // 免费退货标签
      return USER_INDEX_LOCAL_IMG.freeRefund
    default:
      return ''
  }
})

// style2,3情况下优先freeshipping
const isShowFreeShipping = computed(() => {
  let item = props.item
  const benefitStyle = item?.benefitStyle || ''
  const flag = benefitStyle !== STYLE.style1 && item?.freeShippingLabel
  return flag
})

const getBtnBenefitText = computed(() => {
  let item = props.item
  const benefitStyle = item?.benefitStyle || ''
  // 标签类型 1.免邮标签、2.快速运输标签、3.准时宝标签、4.运输时效、5.免费退货标签
  if(benefitStyle == STYLE.style4) {
    // 同时存在的情况按钮显示免邮
    if(item?.freeShippingLabel && item?.savedMoneyTip) {
      return item?.freeShippingLabel
    }
  }

  if(benefitStyle == STYLE.style3) {
    return item?.benefitText
  }
  return ''
})


const getBtnBenefitType = computed(() => {
  let item = props.item
  const benefitStyle = item?.benefitStyle || ''
  // 标签类型 1.免邮标签、2.快速运输标签、3.准时宝标签、4.运输时效、5.免费退货标签
  if(benefitStyle == STYLE.style4) {
    // 同时存在的情况按钮显示免邮
    if(item?.freeShippingLabel && item?.savedMoneyTip) {
      // free shipping
      return 5
    }
  }

  if(benefitStyle == STYLE.style3) {
    return item?.benefitType
  }
  return item?.benefitType || 'default'
})

// style2,3, 4情况下优先freeshipping
const isShowSaveMoneyTip = computed(() => {
  let item = props.item
  const benefitStyle = item?.benefitStyle || ''

  // style4 同时存在要展示
  if(item?.benefitStyle === STYLE.style4) {
    if(item?.freeShippingLabel && item?.savedMoneyTip) {
      return true
    }
  }

  const flag = benefitStyle !== STYLE.style1 && !item?.freeShippingLabel && item?.savedMoneyTip
  return  flag
})


watch(() => props.unpaidOrderActiveClass, (newVal) => {
  if(newVal == 'unpaid-order-box__show') {
    setTimeout(() => {
      timeDomClass.value = 'unpaid-order__transform-time'
    }, 500)
  } else if(newVal == 'unpaid-order-box__hide') {
    timeDomClass.value = 'unpaid-order__hide-time'
  } else if(newVal == 'unpaid-order-box__default') {
    timeDomClass.value = 'unpaid-order__show-time'
  }
}, { immediate: true })



</script>
<style scoped lang="less">
// 设计稿用的 375px
@unit: 375/100vw;

.text-over {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-clamp2 {
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  // word-break: break-word;
}

.text-clamp1 {
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  // word-break: break-word;
}

.center-pos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.unpaid-order {
  font-family: "SF UI Display";
  &__arrow {
    position: absolute;
    display: inline-flex;
    height: .16rem;
    width: .32rem;
    background-size: cover;
    top: -0.2667rem;
    left: .6667rem;
  }
  &__item {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item-left {
    display: flex;
    align-items: center;
    max-width: 70vw;
    position: relative;
    .item-left__img {
      width: 36 / @unit;
      height: 36 / @unit;
      border-radius: 2 / @unit;
      border: 1 / @unit solid #FFF;
    }
    .item-left__num {
      display: inline-block;
      position: absolute;
      bottom: 0;
      width: 9.6vw;
      background: rgba(34, 34, 34, .5);
      color: white;
      text-align: center;
      height: 0.373rem;
      font-size: 11px;
    }
    .item-left__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin-left: 8 / @unit;
      max-width: 220 / @unit;
    }
    .item-left__pay-text {
      margin-right: .1067rem;
      line-height: .3733rem;
    }
    .item-left__incentive {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .item-left__info-content {
      display: flex;
      // flex-direction: column;
      overflow: hidden;
    }
    .item-left__info-total {
      height: .3733rem;
      line-height: .3733rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #000;
      font-size: 12 / @unit;
      font-weight: 400;
      strong {
        font-weight: 700;
      }
    }
    .item-left__saved-box {
      height: 14 / @unit;
      // min-width: 83 / @unit;
      max-width: 114 / @unit;
      display: flex;
      color: #FA6338;
      font-size: 10 / @unit;
      font-weight: 700;
      border-radius: 2 / @unit;
      border: 0.5 / @unit solid rgba(250, 99, 56, 0.60);
      background: #FFF8EB;
      padding: 1 / @unit 2 / @unit;
      align-items: center;
      gap: 2 / @unit;
      // margin-left: 8 / @unit;
      display: flex;
      overflow: hidden;
    }
    .item-left__saved-icon {
      width: 12 / @unit;
      height: 12 / @unit;
      background-size: contain;
    }
    .item-left__saved-text {
      flex: 1;
      line-height: normal;
      overflow: hidden; /* 隐藏溢出的内容 */
      text-overflow: ellipsis; /* 第一行文本溢出时显示省略号 */
      white-space: nowrap;
    }

    .item-left__tip-box { 
      display: flex;
      align-items: flex-start;
      margin-top: 4 / @unit;
    }
    .item-left__tip-icon {
      width: 12 / @unit;
      height: 12 / @unit;
      background-size: contain;
    }
    .item-left__tip-text {
      margin-left: 4 / @unit;
      color: #198055;
      font-size: 12 / @unit;
      font-weight: 500;
      line-height: normal;
      overflow: hidden;
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;
      // text-overflow: ellipsis;
    }
  }
  &__item-right {
    margin-left: .32rem;
    min-width: 2.5133rem;
    position: relative;
    button {
      overflow: visible;
      height: auto;
      max-height: .9067rem;
      padding: 0 .2rem;
    }
  }

  &__right-text {
    display: inline-flex;
    max-width: 3.4667rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  &__btn-text {
    margin-right: .1067rem;
    height: .3733rem;
    line-height: .3733rem;
    max-width: 3.4667rem;
  }
  &__count-down {
    opacity: 0;
    padding: 8/75rem;
    text-wrap: nowrap;
    text-align: center;
    position: absolute;
    top: 0.82rem;
    border-radius: 4/75rem;
    background: #fa6338;
    .font-dpr(20px);
    .right(50%);
    .margin-r(-0.9rem);
    color: #fff;
    z-index: 3;
    &::before {
      position: absolute;
      content: '';
      width: 16/75rem;
      height: 16/75rem;
      background: #fa6338;
      transform: rotate(45deg);
      z-index: 2;
      top: -8/75rem;
      .left(50%);
      .margin-l(-8/75rem);
    }
  }
  &__hide-time {
    opacity: 0;
  }
  &__show-time {
    opacity: 1;
  }
  &__transform-time {
    top: 98/75rem;
    animation: fadeIn .2s forwards;
  }
  &__count-down-info{
    i{
      .font-dpr(20px);
    }
    line-height: normal;
    .font-dpr(20px);
    color: #FFFFFF;
    background: @sui_color_promo;
    border-radius: 2px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    top: 90/75rem;
  }
  to {
    top: 0.82rem;
    opacity: 1;
  }
}

.btn-countdown {
  font-family: "SF UI Display";
  width: 1rem;
  color: #FFF;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  opacity: 0.7;
  line-height: .3733rem;

  span {

  }
}
</style>

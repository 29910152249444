<template>
  <div
    v-cloak
    v-expose="{
      id: '1-25-12-25',
      data: reportParams
    }"
    v-tap="{
      id: '1-25-12-26',
      data: reportParams
    }"
    @click="goToPrimeEvt"
  >
    <div
      class="fusion-member fsp-element"
      :class="{
        'fusion-floor-style': isInFusionFloor,
      }"
    >
      <Card
        :cornerBgImg="IMGS.clubIcon"
        :custom-style="computedStyles?.cardStyle"
        :text="(cardInfo?.cardPriority == 'p2' ? '' : (isPaidExpired && !cardInfo?.mainText) ? cardInfo?.statusTip : cardInfo?.mainText)"
        :expire-tag-style="computedStyles?.expireTagStyle"
      >
        <!-- subtitle -->
        <div class="fusion-member__subtitle">
          <Subtitle :title="cardInfo?.subText" />
        </div> 
        <!-- benefit -->
        <div class="fusion-member__benefit">
          <div
            v-for="(item, index) in benefit"
            :key="index"
            class="fusion-member__benefit_item"
          >
            <Benefit
              v-if="!showFreeGift(item)"
              :bgImg="computedStyles.couponStyle.couponImg[benefit.length][item.type == 'freeShipping' ? 1 : 0]"
              :benefitImg="showIcon(item.type, item) ? item?.icons?.[0] : '' "
              :benefitVal="showIcon(item.type, item) ? '' : item?.mainText"
              :desc="item?.subText"
              :seconds="getCountDownSeconds(item)"
              :cornerText="item?.cornerText"
              :countdownText="item?.countdownText"
              :rightDesc="item?.middleText"
              :customStyle="computedStyles.couponStyle.style[benefit.length]"
            />
            <div
              v-if="showFreeGift(item)"
              class="fusion-member__benefit_imgs"
            >
              <FreeImg
                v-for="(src, i) in item?.icons"
                :key="i"
                :text="item?.subText"
                :bgImg="src"
              />
            </div>
          </div>
        </div> 
        <!-- btn -->
        <div class="fusion-member__btn">
          <Btn
            :text="cardInfo?.buttonTip"
            :custom-style="computedStyles?.btnStyle"
          />
        </div> 
      </Card> 
    </div>
    <!-- 续费弹窗 -->
    <div @click.stop="">
      <PersonRenewDialogNewStyle1
        v-if="renewPopupInfoV3"
        :renew-popup-info="renewPopupInfoV3"
        :is-show="isShowRenewDialogNew1"
        @dialogOpened="openedCallback"
      />
      <PersonRenewDialogNewStyle
        v-if="renewPopupInfoV2"
        :renew-popup-info="renewPopupInfoV2"
        :is-show="isShowRenewDialogNew"
        @dialogOpened="openedCallback"
      />
      <PersonRenewDialog
        v-else
        :renew-pop-up-info="renewPopupInfo"
        :is-show="isShowRenewDialog && !!renewPopupInfo"
        @dialogOpened="openedCallback"
      />
    </div>
    <div @click.stop="">
      <!-- 免费礼物弹窗 优先级续>免费礼物>回本-->
      <FreeGiftDialog
        v-if="!isShowRenewDialog && !isShowRenewDialogNew && !isShowRenewDialogNew1 && showFreeGiftPopup"
        :free-gift-popup-info="freeGiftPopupInfo"
        :is-need-define-animation="isNeedDefineAnimation"
        @dialogClose="handleFreeGiftDialogClose"
        @dialogOpened="openedCallback"
      />
    </div>
    <!-- 回本动效弹窗 todo-->
    <div @click.stop="">
      <PayBackDialog
        :payback-info="paybackInfo"
        :is-show="!isShowRenewDialog && !isShowRenewDialogNew && !isShowRenewDialogNew1 && !showFreeGiftPopup"
        @dialogOpened="openedCallback"
      />
    </div>
  </div>
</template>
<script setup>
import { vExpose, vTap } from 'public/src/pages/common/analysis/directive'
import { computed, inject, ref, onMounted, defineEmits, defineAsyncComponent } from 'vue'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { COUNT_MAP_BY_STYLE, IMGS } from 'public/src/pages/user_index/util/const'
import { canShowRenewDialog, canShowRenewDialogNew } from '../UserRightsPanel/dialogHooks'
import Subtitle from './Subtitle.vue'
import Benefit from './Benefit.vue'
import Btn from './Btn.vue'
import Card from './Card.vue'
import FreeImg from './FreeImg.vue'

const { pushLoginRoute, getABTInfo } = inject('USER_CENTER_CONTEXT') || {}
const abtInfo = getABTInfo()
// 会员卡利益点的展示方式
const { BenifitPresentWays } = abtInfo


/* async component */
const PersonRenewDialogNewStyle = defineAsyncComponent(() => import(/* webpackChunkName: "person-renew-dialog-new-style" */ '../UserRightsPanel/PersonRenewDialogNewStyle/index'))
const PersonRenewDialogNewStyle1 = defineAsyncComponent(() => import(/* webpackChunkName: "person-renew-dialog-new-style" */ '../UserRightsPanel/PersonRenewDialogNewStyle1/index'))
const PersonRenewDialog = defineAsyncComponent(() => import(/* webpackChunkName: "person-renew-dialog" */ '../UserRightsPanel/PersonRenewDialog'))
const PayBackDialog = defineAsyncComponent(() => import(/* webpackChunkName: "pay-back-dialog" */ '../UserRightsPanel/PayBackDialog'))
const FreeGiftDialog = defineAsyncComponent(() => import(/* webpackChunkName: "free-gift-dialog" */ '../UserRightsPanel/free-gift-dialog/FreeGiftDialog'))
const { GB_cssRight, PUBLIC_CDN } = gbCommonInfo


const props = defineProps({
  freeGiftPopupInfo: { type: Object, default: () => ({}) },
  cardInfo: { type: Object, default: () => ({}) },
  computedStyles: {
    type: Object,
    default: () => ({}),
  },
  paybackInfo: { type: Object, default: null },
  renewPopupInfo: { type: Object, default: null },
  renewPopupInfoV2: { type: Object, default: null },
  renewPopupInfoV3: { type: Object, default: null },
  context: { type: String, default: 'independent' },
  isUpAndDownFloor: { type: Boolean, default: false },
})

// 是否需要自定义弹窗动画(弹窗动画:向右上角锚点元素移动，逐渐缩小最后消失)
const isNeedDefineAnimation = ref(false)
// 动画结束后, 需要展示静态图片
const staticBenifitPicture = ref('')



const emits = defineEmits(['openedDialog'])


/* computed */
const isInFusionFloor = computed(() => props.context === 'fusion-floor')
const isPaidExpired = computed(() => Number(props.cardInfo?.status) === 2)

const isShowRenewDialog = computed(() => canShowRenewDialog(props.renewPopupInfo || {}))
const isShowRenewDialogNew = computed(() => canShowRenewDialogNew(props.renewPopupInfoV2 || {}))
const isShowRenewDialogNew1 = computed(() => canShowRenewDialogNew(props.renewPopupInfoV3 || {}))
const showFreeGiftPopup = computed(() => props.freeGiftPopupInfo?.giftInfoList?.length)

const getCountDownSeconds = (item) => {
  if(item.endTime) {
    const gap = (item.endTime - Math.floor(Date.now() / 1000))
    if(gap > 0) {
      return gap
    }
  }
}

const reportParams = computed(() => {
  const { buriedObject } = props.cardInfo
  const item = buriedObject?.find(item => item.buriedKey === 'prime_entry')
  if (!item) return
  return item.defaultParams
})

// 已登录且是付费会员
const loginAndPaidVip = computed(() => props.cardInfo?.isPaid === 1 && isLogin())
const benefit = computed(() => props.cardInfo?.benefit?.slice(0, COUNT_MAP_BY_STYLE[props.cardInfo?.cardPriority]))


/* methods */
const goToPrimeEvt = () => {
  let url = props.cardInfo?.url
  let appRoute = props.cardInfo?.appRoute
  if(appRoute) {
    url = appRoute
  }
  pushLoginRoute?.({
    target: url || '/user/prime?productsource=me',
    bi: 'club',
  })
}

const showFreeGift = (item) => {
  if(item.type == 'freeGiftGoods' && item?.icons?.length) {
    if(props.cardInfo?.benefit?.length == 1 && item.type == 'freeGiftGoods') {
      return true
    }
  }
  return false 
}

const showIcon = (type, item) => {
  return ['freeGift', 'discountIcon', 'pointIcon'].includes(type) && item?.icons?.length
}

const openedCallback = dialogType => {
  emits('dialogOpened', dialogType)
}


const toPay = () => {
  const { url, appRoute } = props.cardInfo
  pushLoginRoute?.({
    target: appRoute || url,
    bi: 'club',
  })
}
// 判断免费礼物弹窗是否需要自定义关闭动画
// const handleFreeGiftDialogCloseAnimation = () => {
//   // 如果会员卡不在视口内,则不需要自定义动画
//   const memberCard = document.querySelector('.paid-vip-ctn')
//   const memberCardRect = memberCard.getBoundingClientRect()
 
//   if (memberCardRect.top < 0) {
//     return false
//   }
//   return true
// }

const handleFreeGiftDialogClose = () => {
  // 弹窗关闭后, 展示gif图片
  staticBenifitPicture.value = props.cardInfo?.giftPicture
  // gif 图执行两次动画后, 展示静态图片
  setTimeout(() => {
    staticBenifitPicture.value = props.cardInfo?.picture
  }, 2008)
}

onMounted(() => {
  // isNeedDefineAnimation.value = props.isUpAndDownFloor && BenifitPresentWays === 'bigpicturewithgif' && handleFreeGiftDialogCloseAnimation()
})

</script>
<style lang="less" scoped>
@import './style/common.less';
.fusion-member {
  
  &__subtitle {
    display: flex;
    align-items: flex-start;
    margin: .16rem 0 .1067rem 0;
    height: .37333rem;
  }

  &__benefit {
    .center();
    &_item:not(:last-child) {
      margin-right: .1067rem;
    }

    &_imgs {
      .center();
      .rights-free-img:not(:last-child) {
        margin-right: .1067rem;
      }
      height: 1.6rem;
    }
  }

  &__btn {
    .center();
    margin-top: .1067rem;
  }
  
}
</style>

<template>
  <CommonWrapper
    :rights-info="rightsInfo"
  >
    <div
      class="new-user-benefits__deliver-goods"
      @click.stop="onModuleClick"
    >
      <div
        v-for="(product, index) in products"
        :key="product.goodID"
        v-expose="{
          id: '1-25-11-28',
          code: 'new-user-benefits-expose-goods',
          data: {
            product,
            content_list: getCccJumpUrl(hrefInfo),
          },
        }"
        class="new-user-benefits__deliver-goods-item"
        :style="{
          backgroundImage: `url(${product.url})`
        }"
        @click.stop="onGoodsItemClick(product, index)"
      >
        <div
          v-if="product.discountRateText"
          class="new-user-benefits__deliver-goods-discount"
          v-text="product.discountRateText"
        ></div>
        <div
          class="new-user-benefits__deliver-goods-price"
        >
          <CamelCasePrice
            class="new-user-benefits__goods-price-camelcase"
            v-bind="getCamelCasePrice(product)"
            :show-camel-price="true"
          />
        </div>
      </div>
    </div>
  </CommonWrapper>
</template>

<script setup name="DeliverGoods">
import { computed } from 'vue'
import { vExpose } from 'public/src/pages/common/analysis/directive'
import CommonWrapper from './CommonWrapper.vue'
import CamelCasePrice from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/CamelCasePrice.vue'
import { handlePriceHtml } from 'public/src/pages/components/product/item_v3/js/utils'
import { getCccJumpUrl } from 'public/src/pages/user_index/service/utils'
import { daEventCenter } from 'public/src/services/eventCenter'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  rightsInfo: {
    type: Object,
    default: () => ({}),
  },
  hrefInfo: {
    type: Object,
    default: () => ({}),
  },
})

const products = computed(() => props.rightsInfo?.goods)
const getCamelCasePrice = product => {
  const { amountWithSymbol, amount } = product
  return handlePriceHtml(amountWithSymbol, amount)
}

const onModuleClick = () => {
  const jumpUrl = getCccJumpUrl(props.hrefInfo) || ''
  setTimeout(() => {
    if (!jumpUrl) return
    location.href = jumpUrl
  }, 300)
  daEventCenter.triggerNotice({
    daId: '1-25-11-27',
    bindData: {
      module_nm: 'new_user_fd',
      goods_id_list: '',
      content_list: jumpUrl,
    },
  })
}

const onGoodsItemClick = (product, index) => {
  const jumpUrl = getCccJumpUrl(props.hrefInfo) || ''
  setTimeout(() => {
    if (!jumpUrl) return
    const goodsIds = products.value.map(product => product.goodID)
    goodsIds.splice(index, 1)
    goodsIds.unshift(product.goodID)
    location.href = `${jumpUrl}${jumpUrl.indexOf('?') > -1 ? '&' : '?'}top_goods_id=${goodsIds.join(',')}`
  }, 300)
  daEventCenter.triggerNotice({
    daId: '1-25-11-27',
    bindData: {
      module_nm: 'new_user_fd',
      goods_id_list: product.goodID,
      content_list: jumpUrl,
    },
  })
}

</script>

<style lang="less" scoped>
.new-user-benefits {
  &__deliver-goods {
    display: flex;
    align-items: center;
    height: 114/37.5rem;
    padding: 33/37.5rem 8/37.5rem 0;
  }
  &__deliver-goods-item {
    position: relative;
    width: 64/37.5rem;
    height: 77/37.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 2/37.5rem;
    overflow: hidden;
    &:not(:first-child) {
      margin-left: 4/37.5rem;
    }
  }
  &__deliver-goods-discount {
    position: absolute;
    top: 0;
    left: 0 /* rtl: unset */;
    right: unset /* rtl: 0 */;
    background-color: #FA6338;
    color: #fff;
    font-size: 9px;
    line-height: 1.2222;
    padding: 1/37.5rem 2/37.5rem 0;
    font-family: 'SF-UI-Text-Regular';
    direction: ltr /* rtl:ltr */;
  }
  &__deliver-goods-price {
    position: absolute;
    bottom: 4/37.5rem;
    left: 50%;
    transform: translateX(-50%);
    height: 13/37.5rem;
    padding: 1/37.5rem 4/37.5rem;
    font-family: 'SF-UI-Text-Regular';
    border-radius: 6.5/37.5rem;
    background-color: rgba(255, 255, 255, 0.80);
  }
  &__goods-price-camelcase {
    line-height: 11/37.5rem;
    font-family: 'SF-UI-Text-Bold';
    font-weight: 700;
    color: #FA6338;
    font-size: 8px;
    direction: ltr /* rtl:ltr */;
    :deep(.product-card__camel-case-price__number-before-dot) {
      font-size: 11px;
    }
  }
}
</style>

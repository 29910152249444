<template>
  <div class="unpaid-order__item">
    <!-- left -->
    <div class="unpaid-order__item-left">
      <img
        class="item-left__img"
        :src="transformImg({ img: props.item?.goodImage || '' })"
      />
      <span
        v-if="props.item?.goodNum"
        class="item-left__num"
      >{{ props.item?.goodNum }}</span>
      <div class="item-left__info">
        <div class="item-left__info-content">
          <div
            class="item-left__info-total" 
            v-html="props.item?.upTips"
          ></div>
          <div
            v-if="props.item?.savedMoneyTip"
            class="item-left__saved-box"
          >
            <img 
              class="item-left__saved-icon"
              :src="USER_INDEX_LOCAL_IMG.downwardTrendIcon"
            />
            <ScrollingText 
              :text="props.item?.savedMoneyTip"
            />
          </div>
        </div>
        <div class="item-left__tip-box">
          <img 
            :src="transformImg({ img: getTipsIcon })"
            class="item-left__tip-icon"
          />
          <div
            class="item-left__tip-text"
          >
            {{ props.item?.freeShippingLabel }}
          </div>
        </div>
      </div>
    </div>

    <!-- right -->
    <div class="unpaid-order__item-right">
      <s-button
        :type="['primary', 'H48PX']"
        @click.stop="payNowHandle"
      >
        {{ clickButton?.content || '' }}
      </s-button>
      <div 
        :class="`unpaid-order__count-down ${timeDomClass}`"
      >
        <div class="unpaid-order__count-down-info">
          <i class="suiiconfont sui_icon_hourglass_12px_2"></i>
          <span v-html="countDownTips"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { SButton } from '@shein-aidc/sui-button/mobile'
// 未支付订单(新)
import { ref, onMounted, defineEmits, computed, watch, getCurrentInstance } from 'vue'
import { CountDown, transformImg } from '@shein/common-function'
import ScrollingText from './ScrollingText'
import { USER_INDEX_LOCAL_IMG } from '../../../consts.js'
import { formatUrlQuery } from '../../../service/utils.js'
const { langPath } = gbCommonInfo


const instance = getCurrentInstance()
const routerInstance = instance?.proxy?.$router

const countTimer = ref(new CountDown())
const timeDomClass = ref('')

const props = defineProps({
  item: { type: Object, default: () => {} },
  unpaidOrderActiveClass: { type: String, default: '' }
})

const emits = defineEmits(['countdownEnd'])

onMounted(() => {
/* 未支付订单倒计时 */
  const { expireCountdown } = props.item
  if (expireCountdown) {
    countTimer.value.start({
      seconds: expireCountdown,
      endFunc: () => {
        // 倒计时结束
        emits('countdownEnd')
      }
    })
  }
})

const payNowHandle = () => {
  const { jumpUrl = '', jumpParams = {} } = clickButton.value.urlObject
  const { billno, page_from, from_type, show_selected_pay_method } = jumpParams
  const urlQuery = formatUrlQuery({
    goBack: 1,
    show_error_guide_payment: show_selected_pay_method,
    from_type,
    page_from
  })
  routerInstance.push(`${langPath}${jumpUrl}/${billno}?${urlQuery}`)
  const reportInfo = (props.item?.clickButton?.buriedObject || []).find(item => item.buriedType === 2 && item.buriedKey === 'order_floor_pay')
  daEventCenter.triggerNotice({
    daId: '1-25-11-1003',
    extraData: reportInfo?.defaultParams,
  })
}

const clickButton = computed(() => props.item?.clickButton)

const countDownTips = computed(() => {
  const { H, M, S } = countTimer.value?.timeObj || {}
  return `<span>${H}</span> : <span>${M}</span> : <span>${S}</span>`
})

const getTipsIcon = computed(() => {
  const labelType = props.item?.labelType || ''
  switch(+labelType) {
    case 1: // 包邮
      return USER_INDEX_LOCAL_IMG.freeShippingSolid
    case 2: // 快速运输标签
      return USER_INDEX_LOCAL_IMG.fastFreightSolid
    case 3: // 准时宝标签
      return USER_INDEX_LOCAL_IMG.completeSolid
    case 4: // 运输时效
      return USER_INDEX_LOCAL_IMG.transportationSolid
    case 5: // 免费退货标签
      return USER_INDEX_LOCAL_IMG.freeRefundSolid
    default:
      return ''
  }
})


watch(() => props.unpaidOrderActiveClass, (newVal) => {
  if(newVal == 'unpaid-order-box__show') {
    setTimeout(() => {
      timeDomClass.value = 'unpaid-order__transform-time'
    }, 500)
  } else if(newVal == 'unpaid-order-box__hide') {
    timeDomClass.value = 'unpaid-order__hide-time'
  } else if(newVal == 'unpaid-order-box__default') {
    timeDomClass.value = 'unpaid-order__show-time'
  }
}, { immediate: true })



</script>
<style scoped lang="less">
// 设计稿用的 375px
@unit: 375/100vw;
.unpaid-order {
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item-left {
    display: flex;
    align-items: center;
    max-width: 70vw;
    position: relative;
    .item-left__img {
      width: 36 / @unit;
      height: 36 / @unit;
      border-radius: 2 / @unit;
      border: 1 / @unit solid #FFF;
    }
    .item-left__num {
      display: inline-block;
      position: absolute;
      bottom: 0;
      width: 9.6vw;
      background: rgba(34, 34, 34, .5);
      color: white;
      text-align: center;
      height: 0.373rem;
      font-size: 11px;
    }
    .item-left__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin-left: 8 / @unit;
      max-width: 220 / @unit;
    }
    .item-left__info-content {
      display: flex;
      overflow: hidden;
    }
    .item-left__info-total {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #000;
      font-size: 12 / @unit;
      font-weight: 400;
      strong {
        font-weight: 700;
      }
    }
    .item-left__saved-box {
      min-width: 83 / @unit;
      max-width: 114 / @unit;
      display: flex;
      color: #FA6338;
      font-size: 10 / @unit;
      font-weight: 700;
      border-radius: 2 / @unit;
      border: 0.5 / @unit solid rgba(250, 99, 56, 0.60);
      background: #FFF8EB;
      padding: 1 / @unit 2 / @unit;
      align-items: center;
      gap: 2 / @unit;
      margin-left: 8 / @unit;
      display: flex;
      overflow: hidden;
    }
    .item-left__saved-icon {
      width: 12 / @unit;
      height: 12 / @unit;
      background-size: contain;
    }
    .item-left__saved-text {
      flex: 1;
      line-height: normal;
      overflow: hidden; /* 隐藏溢出的内容 */
      text-overflow: ellipsis; /* 第一行文本溢出时显示省略号 */
      white-space: nowrap;
    }

    .item-left__tip-box { 
      display: flex;
      align-items: center;
      margin-top: 4 / @unit;
    }
    .item-left__tip-icon {
      width: 12 / @unit;
      height: 12 / @unit;
      background-size: contain;
    }
    .item-left__tip-text {
      margin-left: 4 / @unit;
      color: #198055;
      font-size: 12 / @unit;
      font-weight: 500;
      line-height: normal;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
  &__item-right {
    margin-left: .32rem;
    width: 1.76rem;
    position: relative;
  }
  &__count-down {
    opacity: 0;
    padding: 8/75rem;
    text-wrap: nowrap;
    text-align: center;
    position: absolute;
    top: 0.82rem;
    border-radius: 4/75rem;
    background: #fa6338;
    .font-dpr(20px);
    .right(50%);
    .margin-r(-0.9rem);
    color: #fff;
    z-index: 3;
    &::before {
      position: absolute;
      content: '';
      width: 16/75rem;
      height: 16/75rem;
      background: #fa6338;
      transform: rotate(45deg);
      z-index: 2;
      top: -8/75rem;
      .left(50%);
      .margin-l(-8/75rem);
    }
  }
  &__hide-time {
    opacity: 0;
  }
  &__show-time {
    opacity: 1;
  }
  &__transform-time {
    top: 98/75rem;
    animation: fadeIn .2s forwards;
  }
  &__count-down-info{
    i{
      .font-dpr(20px);
    }
    line-height: normal;
    .font-dpr(20px);
    color: #FFFFFF;
    background: @sui_color_promo;
    border-radius: 2px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    top: 90/75rem;
  }
  to {
    top: 0.82rem;
    opacity: 1;
  }
}
</style>

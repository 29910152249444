
import { ref, onMounted, nextTick } from 'vue'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

import { setPageName, showTopTrendCard } from 'public/src/pages/product_app/store/modules/trend_landing/topTrend.utils.js'
import { fetchFooterRcmdData, fetchFooterRcmdDataBff } from '../api/index.js'
import { formatGoodsData } from 'public/src/pages/trend/bff.utils'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
const RECORD_KEY = 'usercenter_newspannel_display_logic'
const FIRST_LOAD_TAG_KEY = 'usercenter_newspannel_first_load'

// news疲劳值
export const useNewsFatigue = ({ getArticleTitleLists, routeInfo, getClientAbt }) => {
  const isShow = ref(false)

  const needExp = () => {
    const { to, from } = routeInfo.value
    // 首次链接访问（非刷新页面）
    if (!sessionStorage.getItem(FIRST_LOAD_TAG_KEY) ) return true
    // 其他一级tab跳过来
    if (to?.name === 'UserIndex' && ['config_index', 'page_category', 'campaigns'].includes(from?.name)) return true
    return false
  }
  const getRecord = () => {
    return JSON.parse(localStorage.getItem(RECORD_KEY) || '{}')
  }
  const setRecord = ({ type, time } = {}) => {
    let obj = getRecord()
    switch (type) {
      case 'addExp':
        if (!needExp()) return
        obj = {
          ...obj,
          expNum: (obj.expNum || 0) + 1
        }
        break
      case 'addCli':
        obj = {
          ...obj,
          cliNum: (obj.cliNum || 0) + 1
        }
        break
      case 'reset':
        obj = {
          time,
          expNum: 1,
          cliNum: 0,
        }
        break
    }
    localStorage.setItem(RECORD_KEY, JSON.stringify(obj))
  }

  const getStrategy = async () => {
    const { PageMeNews } = await getUserAbtData()

    // 旧策略返回 'old' ，新策略返回 number（0,1,2 ...）代表疲劳值曝光次数
    let strategy = PageMeNews?.p?.pageme_news_exposure
    if (!strategy) {
      strategy = strategy === 0 || strategy === '0' ? 0 : 'old' // 兜底值
    }
    const isNewStrategy = strategy !== 'old'
    return {
      strategy,
      isNewStrategy,
    }
  }

  const handleDisplay = async () => {
    const { strategy, isNewStrategy } = await getStrategy()
    if (isNewStrategy && +strategy) { // 新策略
      const { time, expNum, cliNum } = getRecord()
      const curTIme = getArticleTitleLists.value[0].startTime
      const hasClicked = (cliNum || 0) === 0
      if (time === curTIme && expNum >= +strategy && hasClicked) { // 曝光n次，且该设备未点击过news栏
        isShow.value = false
      } else {
        isShow.value = true
        time !== curTIme ? setRecord({ type: 'reset', time: curTIme }) : setRecord({ type: 'addExp' })
      }
      return
    }
  
    isShow.value = true
  }

  onMounted(() => {
    handleDisplay()
    sessionStorage.setItem(FIRST_LOAD_TAG_KEY, '1') // 用于判断是首次加载还是刷新页面
  })

  return {
    isShow,
    setRecord,
    handleDisplay,
  }
}

let CachedTrendCardFlowData = null

export const useTrendFlow = () => {

  const trendFlowData = ref([])
  const trendFlowPageNum = ref(1)
  const sheinClubInfo = ref({})
  const firstTrendFlowPending = ref(true)
  const abtSource = ref({})

  const handleClubInfo = (data) => {
    const sheinClubInfoRes = data[3] || {}
    const isPaid = sheinClubInfoRes?.info.is_paid ?? 0
    return {
      sheinClubType: 3,
      reqSheinClub: true,
      isPaid,
      _from: 'bff'
    }
  }

  const handleBffResponse = (data, pageNum, productLanguage) => {
    const [trendWordFlowRes = {}, choiceStoreFlowRes = {}, trendCardFlowRes = {}] = data || []
    const isPaid = sheinClubInfo.value.isPaid
    const formatGoodsDataOptions = {
      suggestedSaleType: gbCommonInfo.IS_SUGGESTED,
      language: productLanguage ?? {},
      lang: gbCommonInfo.lang ?? 'us',
      isPaid,
      hideSuggestedSalePrice: !!gbCommonInfo.IS_SUGGESTED,
    }
    // const sheinClubInfo = {
    //   sheinClubType: 3,
    //   reqSheinClub: true,
    //   isPaid,
    //   _from: 'bff'
    // }
    const trendWordFlow = {
      data: trendWordFlowRes.info?.trendInfo?.map(item => {
        const newItem = formatGoodsData(item.product, formatGoodsDataOptions)
        newItem.customCateIds = ''
        // TODO: 售罄字段处理
        newItem.stock = 999
        newItem.is_on_sale = 1
        return {
          ...item,
          product: newItem,
        }
      }) || []
    }
    const fashionStoreContents = choiceStoreFlowRes?.info.fashionStoreContents ?? []
    const choiceStoreFlow = {
      data: fashionStoreContents.map(fashionStoreContent => {
        const choiceLogoImg = fashionStoreContent?.trendIpImg ?? {}
        const appMarkInfo = fashionStoreContent?.appMarkInfo ?? {}
        const product = fashionStoreContent?.storeRecommendProducts?.[0] ?? {}
        const storeType = fashionStoreContent?.storeType || ''
        const productItem = goodsTransformation(product, {
          ...formatGoodsDataOptions,
          hideSuggestedSalePrice: false,
        })
        const title = fashionStoreContent?.title ?? ''
        return {
          storeType,
          ...productItem,
          storeInfo: {
            ...productItem.storeInfo,
            title,
          },
          ...appMarkInfo,
          choiceLogoImg,
          recMark: appMarkInfo.recMark
        }
      }) ?? []
    }
    let trendCardFlow = null
    if (pageNum === 1) {
      // 除第一页以外都为空
      trendCardFlow = {
        data: {
          ...trendCardFlowRes?.info ?? {},
          products: trendCardFlowRes?.info.trendInfo ?? []
        }
      }
      CachedTrendCardFlowData = trendCardFlow
    } else {
      trendCardFlow = CachedTrendCardFlowData
    }
    
    return { trendWordFlow, trendCardFlow, choiceStoreFlow }
  }

  // 请求趋势内容体数据
  const fetchTrendFlowData = async ({ unuseCardSlotInfoNums, unuseCardSlotSplitInfoNums } = {}, itemLanguage = null) => {
    try {
      // console.log('unusedCardSlotSplitInfoData======', unuseCardSlotSplitInfoNums, ';unuseCardSlotInfoNums======', unuseCardSlotInfoNums)
      setPageName('user_index')
      const abtInfo = await getUserAbtData()
      const { pagemefeedtrend, listwithCoupon, pwameRecommend, pagemefeedshop } = abtInfo
      abtSource.value = { pagemefeedtrend, listwithCoupon, pwameRecommend, pagemefeedshop }
      const trendFlowAbt = {
        pagemefeedtrendword: +abtSource.value.pagemefeedtrend?.p?.pagemefeedtrendword || 0, 
        pagemefeedtrendcard: +abtSource.value.pagemefeedtrend?.p?.pagemefeedtrendcard || 0,
        pagemefeedtrendshop: +abtSource.value.pagemefeedshop?.p?.pagemefeedshop || 0,
        // choicestores: abtSource.value.pagemefeedshop?.p?.choicestores === 'yes'
      }
      const { pagemefeedtrendword, pagemefeedtrendcard, pagemefeedtrendshop } = trendFlowAbt
      // 还剩2个内容体时，请求剩余数据
      // if (pagemefeedtrendword === 0 || unuseCardSlotInfoNums > 2 || unuseCardSlotInfoNums === 0) return
      
      // start 
      // 新增内容体类型时，这里需要添加相关逻辑
      if (pagemefeedtrendword === 0 && pagemefeedtrendshop === 0) {
        // 当趋势内容体和店铺内容体abt都=0时，不需要发起请求
        return
      }
      if (unuseCardSlotSplitInfoNums) { // 未使用内容体集合非undefined时，才进行以下判断
        const { TrendWordCard = 0, TopTrendCard = 0, ChoiceStoreCard = 0, FashionStoreCard = 0 } = unuseCardSlotSplitInfoNums
        // const unuseTrendWord = unuseCardSlotSplitInfoNums?.TrendWordCard || 0
        // const unuseTopTrend = unuseCardSlotSplitInfoNums?.TopTrendCard || 0
        // const unuseChoiceStore = unuseCardSlotSplitInfoNums?.ChoiceStoreCard || 0
        // const unusefFashionStore = unuseCardSlotSplitInfoNums?.FashionStoreCard || 0
        const unuseStoreCard = ChoiceStoreCard + FashionStoreCard
        const unuseTrendCard = TrendWordCard + TopTrendCard
        // 内容体未使用的数量大于2，就不需要发起请求
        if (unuseTrendCard > 2 || unuseStoreCard > 2) {
          return
        }
        // 剩余的未使用内容体都为0，表示没有下一页了，不需要再请求
        if (unuseTrendCard === 0 || unuseStoreCard === 0) {
          return
        }
      }
      
      // end

      // const startIndex = trendFlowData.value.length ? trendFlowData.value[trendFlowData.value.length - 1].index : 0
      
      // const startIndex = trendFlowData.value.length ? trendFlowData.value.filter(n => !['ChoiceStoreCard'].includes(n.type)).length : 0

      const getTrendCardFlow = showTopTrendCard(true) && pagemefeedtrendcard > 0
      const getChoiceStore = trendFlowAbt.pagemefeedtrendshop > 0
      
      const isTrendCard = (n) => ['TopTrendCard', 'TrendWordCard'].includes(n.type)
      const isStoreCard = (n) => ['FashionStoreCard', 'ChoiceStoreCard'].includes(n.type)
      let startIndex = 0
      if (getTrendCardFlow) {
        startIndex = trendFlowData.value.length ? trendFlowData.value.filter(isTrendCard).length : 0
      } else if (getChoiceStore) {
        startIndex = trendFlowData.value.length ? trendFlowData.value.filter(isStoreCard).length : 0
      }

      const res1 = await fetchFooterRcmdDataBff({
        getTrendCardFlow,
        trendFlowPageNum: trendFlowPageNum.value,
        getChoiceStore,
      })
      // const res = await fetchFooterRcmdData({
      //   getTrendCardFlow,
      //   trendFlowPageNum: trendFlowPageNum.value,
      // })
      if(trendFlowPageNum.value === 1) {
        sheinClubInfo.value = handleClubInfo(res1)
      }
      const res = handleBffResponse(res1, trendFlowPageNum.value, itemLanguage)
      const { trendWordFlow = {}, trendCardFlow = {}, choiceStoreFlow } = res
      // if (Object.keys(sheinClubInfo.value).length === 0) {
      //   sheinClubInfo.value = res?.sheinClubInfo || {}
      // }
      const validTrendCardData = trendCardFlow.data?.products?.length
      
      const currentTrendFlowLen = trendFlowData.value.length ?? 0
      const filledList = new Array(Math.max(trendWordFlow.data?.length ?? 0, choiceStoreFlow.data?.length ?? 0)).fill(null)
      const _trendFlowData = filledList?.map((_, i) => {
        const slotIndex = (startIndex + i) * 20 + (pagemefeedtrendword - 1)
        // 店铺的插槽索引
        const slotIndexForStore = (startIndex + i) * 20 + (pagemefeedtrendshop - 1)
        // 趋势频道内容体与趋势词内容体共用同一套插坑逻辑，在趋势词内容体插坑中仅展示一次
        const isTrendCardSlot = validTrendCardData && getTrendCardFlow && (pagemefeedtrendcard - 1) === i && trendFlowPageNum.value <= 1
        const trendData = isTrendCardSlot ? trendCardFlow.data : trendWordFlow.data?.[i]
        const isValidTrendData = !!trendData
        const storeData = choiceStoreFlow.data?.[i]
        const isValidStoreData = !!storeData
        const type = isTrendCardSlot ? 'TopTrendCard' : 'TrendWordCard'
        const storeType = storeData?.storeType === 'fashionStore' ? 'FashionStoreCard' : 'ChoiceStoreCard'
        return (
          [ isValidTrendData ? { slotIndex, data: trendData, type } : null, 
            isValidStoreData ? { slotIndex: slotIndexForStore, data: storeData, type: storeType } : null
          ].filter(store => !!store))
      }).flat().map((n, idx) => {
        return {
          ...n,
          index: currentTrendFlowLen + idx + 1
        }
      }) 
      
      const newTrendFlowData = trendFlowData.value.concat(_trendFlowData)
      // console.log('newTrendFlowData: ', unuseCardSlotInfoNums, newTrendFlowData)
      trendFlowData.value = newTrendFlowData
      trendFlowPageNum.value += 1
    } finally {
      firstTrendFlowPending.value = false
    }
  }

  const resetTrendFlow = (itemLanguage) => {
    trendFlowPageNum.value = 1
    trendFlowData.value = []
    firstTrendFlowPending.value = true
    fetchTrendFlowData(undefined, itemLanguage)
  }

  return {
    resetTrendFlow,
    firstTrendFlowPending,
    trendFlowData,
    fetchTrendFlowData,
    sheinClubInfo,
    abtSource,
  }
}

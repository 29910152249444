<template>
  <!-- 融合楼层，左右结构 -->
  <UserRightsFusionFloorNew
    v-if="showFusionFloorNew"
    :rights-info="rightsInfo"
    @dialogOpened="openCallback"
  />
  <!-- 融合楼层，左右结构 -->
  <UserRightsFusionFloor
    v-else-if="showFusionFloor"
    :rights-info="rightsInfo"
    @dialogOpened="openCallback"
  />
  <!-- 上下楼层 -->
  <div v-else>
    <div
      v-for="(card, i) in cardList"
      :key="i"
    >
      <MemberCard
        v-if="card.cardType === UserRightsCardType.MEMBER_CARD"
        :card-info="card"
        :payback-info="rightsInfo?.payback_info"
        :renew-popup-info="rightsInfo?.renew_pop_up_info"
        :renew-popup-info-v2="rightsInfo?.renew_pop_up_info_v2"
        :renew-popup-info-v3="rightsInfo?.renew_pop_up_info_v3"
        :free-gift-popup-info="rightsInfo?.freeGiftPopupInfo"
        :is-up-and-down-floor="!showFusionFloor"
        @dialogOpened="openCallback"
      />
      <SuperSavePannel
        v-else-if="card.cardType === UserRightsCardType.SUPER_SAVE"
        :card-info="card"
      />
    </div>
  </div>
</template>

<script setup name="UserRightsPanel">
import { computed, ref, watch } from 'vue'
import MemberCard from './MemberCard.vue'
import SuperSavePannel from './SuperSavePanel.vue'
import UserRightsFusionFloor from 'public/src/pages/user_index/components/UserRightsFusionFloor/ModuleIndex.vue'
import UserRightsFusionFloorNew from 'public/src/pages/user_index/components/UserRightsFusionFloorNew/ModuleIndex.vue'
import { UserRightsCardType } from 'public/src/pages/user_index/service/consts'
import { reportUserCenterDialogSuccess } from 'public/src/pages/user_index/service/utils'
import { canShowRenewDialog, canShowRenewDialogNew, verifyPaybackCache } from './dialogHooks'

const CardStyle = {
  UP_BOTTOM: 'v1',
  FUSION_FLOOR: 'v2',
  FUSION_FLOOR_NEW: 'v3',
}

const DialogTypeEnum = {
  NEW_RENEW_DIALOG: 'NEW_RENEW_DIALOG',
  NEW_RENEW_DIALOG1: 'NEW_RENEW_DIALOG1',
  RENEW_DIALOG: 'RENEW_DIALOG',
  PAYBACK_DIALOG: 'PAYBACK_DIALOG',
  FREE_GIFT_DIALOG: 'FREE_GIFT_DIALOG',
  NO_DIALOG: 'NO_DIALOG'
}

// 弹窗类型上报以下枚举值
const DialogTypeEnumMapper = {
  NEW_RENEW_DIALOG: 1,
  NEW_RENEW_DIALOG1: 2,
  RENEW_DIALOG: 3,
  PAYBACK_DIALOG: 4,
  FREE_GIFT_DIALOG: 5,
}

const props = defineProps({
  rightsInfo: {
    type: Object,
    default: () => ({}),
  },
})

const popDialogType = ref(null)

// 是否上报过弹框弹出信息
let hasReportDialog = false

const cardList = computed(() => props.rightsInfo?.member_card_list || [])
const showFusionFloor = computed(() => cardList.value.length > 0 && cardList.value.every(card => card.cardStyle === CardStyle.FUSION_FLOOR))

const showFusionFloorNew = computed(() => cardList.value.length > 0 && cardList.value.every(card => card.cardStyle === CardStyle.FUSION_FLOOR_NEW))

// 根据权益信息获取即将弹出的弹窗
const calcPopInfo = () => {
  if (!cardList.value.find(n => n.cardType === UserRightsCardType.MEMBER_CARD)) {
    return DialogTypeEnum.NO_DIALOG
  }
  // eslint-disable-next-line no-unused-vars
  const { freeGiftPopupInfo, renew_pop_up_info, renew_pop_up_info_v2, renew_pop_up_info_v3, payback_info } = props.rightsInfo ?? {}
  const willNewRenewDialogShow = renew_pop_up_info_v2 && canShowRenewDialogNew(renew_pop_up_info_v2)
  if (willNewRenewDialogShow) {
    return DialogTypeEnum.NEW_RENEW_DIALOG
  }
  // 新版v3续费弹窗弹出
  const willNewRenewDialog1Show = renew_pop_up_info_v3 && canShowRenewDialogNew(renew_pop_up_info_v3)
  if (willNewRenewDialog1Show) {
    return DialogTypeEnum.NEW_RENEW_DIALOG1
  }
  const willRenewDialogShow = renew_pop_up_info && canShowRenewDialog(renew_pop_up_info)
  if (willRenewDialogShow) {
    return DialogTypeEnum.RENEW_DIALOG
  }
  if (freeGiftPopupInfo?.giftInfoList?.length > 0) {
    return DialogTypeEnum.FREE_GIFT_DIALOG
  }
  if (payback_info) {
    const isCached = verifyPaybackCache(payback_info)
    if (!isCached) {
      return DialogTypeEnum.PAYBACK_DIALOG
    }
  }
  return DialogTypeEnum.NO_DIALOG
}

watch(() => props.rightsInfo, val => {
  if (!val) return
  popDialogType.value = calcPopInfo()
  if (popDialogType.value === DialogTypeEnum.NO_DIALOG) return
  setTimeout(() => {
    if (!hasReportDialog) {
      reportUserCenterDialogSuccess({
        dialogName: popDialogType.value,
        tags: {
          status: '0',
          dialogValue: DialogTypeEnumMapper[dialogType] + '',
        },
      })
    }
  }, 10000)
})

const openCallback = dialogType => {
  hasReportDialog = true
  reportUserCenterDialogSuccess({
    dialogName: dialogType,
    tags: {
      status: dialogType === popDialogType.value ? '1' : '0',
      dialogValue: DialogTypeEnumMapper[dialogType] + ''
    }
  })
}

// onMounted(() => {
//   popDialogType.value = calcPopInfo()
//   if (popDialogType.value === DialogTypeEnum.NO_DIALOG) return
//   setTimeout(() => {
//     if (!hasReportDialog) {
//       reportUserCenterDialogSuccess({
//         metricName: popDialogType.value,
//         tags: {
//           status: '0'
//         }
//       })
//     }
//   }, 5000)
// })
</script>


export const IMGS = {
  clubIcon: 'https://img.ltwebstatic.com/images3_ccc/2025/02/08/42/1739001735e6fec238861c1ca5ed1dd8392bc3109c.png',
  saverIcon: 'https://img.ltwebstatic.com/images3_ccc/2025/02/08/8b/173900175327eb5cc39677979325b8f7e7961ce28f.png',
  // 免费礼物券@3x
  couponSingle: 'https://img.ltwebstatic.com/images3_ccc/2025/02/08/11/17389961670608cd8f330ef782824a6bde7004522e.png',
  // 券包@3x
  couponBag: 'https://img.ltwebstatic.com/v4/p/ccc/2025/03/10/cc/1741592555cd1397d844b16617b81811b28bb77e80.png',
  couponBig0: 'https://img.ltwebstatic.com/v4/p/ccc/2025/03/10/83/17415947579bb0f008e2666aa6a319334a4a5cbcfa.webp',
  couponBig1: 'https://img.ltwebstatic.com/v4/p/ccc/2025/03/10/1f/17415951935abe5027c6c6fd4c16d0043e7259dc4b.webp',
  couponMedium0: 'https://img.ltwebstatic.com/v4/p/ccc/2025/03/10/3b/17415954221f9c1cc38c42ba58d78cba31c330873f.webp',
  couponMedium1: 'https://img.ltwebstatic.com/v4/p/ccc/2025/03/10/d5/17415955273777705370ed4c1c97f4732eeae2a7ca.webp',
  couponSmall0: 'https://img.ltwebstatic.com/v4/p/ccc/2025/03/10/57/1741592233faff23d2d4b6dbc3e78814651fb0c9f9.png',
  couponSmall1: 'https://img.ltwebstatic.com/v4/p/ccc/2025/03/10/d1/1741592372d373ac39d9deca711e771fab8a50db98.png',
  pairSmall0: 'https://img.ltwebstatic.com/images3_ccc/2025/02/08/8f/1739000331fabaf68e80548ca1d25480669d47f2d3.png',
  pairSmall1: 'https://img.ltwebstatic.com/images3_ccc/2025/02/08/cd/173900037756da4bde436fe6bf934c54306cb867c0.png',
  pairMedium0: 'https://img.ltwebstatic.com/images3_ccc/2025/02/08/cb/1739000353fafed6fc19be8f8c3e73bcf9d6adcc41.png',
  pairMedium1: 'https://img.ltwebstatic.com/images3_ccc/2025/02/27/ca/1740644987a73d9620e63a3e8da59bddc8afe51ac2.webp',
  freeGift: 'https://img.ltwebstatic.com/images3_ccc/2025/01/22/ec/17375343793ea1c718f6f104dd8bde60b9f2e82f98.png',
  
}
  
export const COUNT_MAP_BY_STYLE = {
  p1: 3,
  p2: 1,
  p3: 2,
}

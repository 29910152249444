<template>
  <div
    class="rights-card"
    :style="customStyle"
  >
    <div class="rights-card__header">
      <!-- 权益角标 -->
      <div
        v-if="cornerBgImg"
        class="rights-card__corner"
        :style="{
          backgroundImage: `url(${cornerBgImg})`, 
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat'
        }"
      >
      </div>
      <TagText
        v-if="text"
        :custom-style="expireTagStyle"
      >
        <TemplateText
          :text="text"
          cls="rights-card__benefit-text"
          val=""
        />
      </TagText>
    </div>
    <slot></slot>
  </div>
</template>

<script setup name="card">
import { ref, onMounted, onUnmounted, computed } from 'vue'
import TagText from './TagText.vue'
import TemplateText from './TemplateText.vue'

const props = defineProps({
  customStyle: {
    type: Object,
    default: () => {},
  },
  cornerBgImg: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  expireTagStyle: {
    type: Object,
    default: () => {},
  }
})


onMounted(() => {
})



onUnmounted(() => {
})
</script>
<style lang="less" scoped>
@import './style/common.less';
.rights-card {
  position: relative;
  border-radius: .1067rem;
  background: #FFF;
  box-shadow: 0px .08rem .16rem 0px rgba(0, 0, 0, 0.05);
  padding: .2133rem;
  border: 1px solid rgba(0, 0, 0, .03);

  width: 6.5067rem;
  // height: 3.5733rem;

  &__header {
    display: flex;
    align-items: center;
  }

  &__corner {
    width: 2.1333rem;
    min-width: 2.1333rem;
    height: .4501rem;
    padding: .0474rem 0px;
    gap: .0474rem;
  }

  
}
</style>

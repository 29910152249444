export const JUMP_URL = {
  USER_COUPON: '/user/coupon',
  USER_POINTS: '/user/points',
  USER_SETTING: '/user/setting',
  USER_WALLET: '/user/wallet',
  USER_SUPPORT: '/user/support',
  USER_ADDRESSBOOK: '/user/addressbook',
  USER_INDEX: '/user/index',
  USER_ACCOUNT_SETTING: '/user/account_setting',
  USER_ORDERS_LIST: '/user/orders/list',
  USER_MEMBERSHIP: '/user/membership',
  SHEINX: '/sheinx',
  PRODUCT_ARTICLE: '/product/article',
  USER_GIFT_CARD: '/user/gift_card',
  AFFILIATE: '/affiliate',
  EXCHANGE: '/exchange',
  STORE_FOLLOWING: '/store/following',
  TRIAL_USER_FREE_TRAIL: '/trial/user_free_trail',
}

export const RISK_PATH_WHITE_LIST = [JUMP_URL.USER_SETTING, JUMP_URL.USER_GIFT_CARD, JUMP_URL.USER_MEMBERSHIP]

export const EXPIRE_GAME_MONEY_CACHE_KEY = 'personal-center__expire-game-money'
export const ExpireTipsClickStatus = {
  // 不再展示
  NO_LONGER_SHOW: 1,
  // 可关闭
  CAN_BE_CLOSED: 2,
}
export const IS_CLIENT = typeof window !== 'undefined'

export const USER_INDEX_LOCAL_IMG = {
  closeImage: 'https://shein.ltwebstatic.com/svgicons/2024/05/21/1716261449791297913.svg',
  defaultBg: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/af/171626169214cbd27645c88a79f0f04bdbbd204487.png',
  priceBg: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/af/1716261692abb2503734bf8ef53c9e78b640f769bb.png',
  mainBgTop: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/af/17162616921abf81c4f528ec2f69d3f7a6a6d1b620.png',
  moreIcon: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/af/1716261692e6dc32a53b8e0a03f172f15b90d8204f.png',
  rectangleIcon: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/0c/17162727199d8334642f5e9846f1a728e454db5c82.png',
  downwardTrendIcon: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/af/17162616924797aa14a90fefe17685ab45d39332b7.png',
  freeShipping: 'https://img.ltwebstatic.com/images3_ccc/2024/07/25/ff/17218984127e3d044b7a6e1e5a1bc6863b245e5ef0.png', // 包邮
  freeShippingSolid: 'https://img.ltwebstatic.com/images3_ccc/2024/08/07/b9/1723015532e66f82c1232322abe928e6846ab1a7ac.png', // 包邮
  fastFreight: 'https://img.ltwebstatic.com/images3_ccc/2024/07/25/63/17218984441037c1ca4c9c86754b16025ee34a83bd.png', // 快速运输
  fastFreightSolid: 'https://img.ltwebstatic.com/images3_ccc/2024/08/07/f9/1723015662c3a82f4cf554f6df34225108c723b02e.png', // 快速运输
  freeRefund: 'https://img.ltwebstatic.com/images3_ccc/2024/07/25/07/1721898473c1084a089e7e6257b0f973c66407a282.png', // 免费退换
  freeRefundSolid: 'https://img.ltwebstatic.com/images3_ccc/2024/08/07/0f/1723015715022afe6e9793be6946001732e87e4632.png', // 免费退换
  complete: 'https://img.ltwebstatic.com/images3_ccc/2024/07/25/62/17218984950cc169f330cf6fa15d75235a25a18b7d.png', // 准时宝 
  completeSolid: 'https://img.ltwebstatic.com/images3_ccc/2024/08/07/eb/17230157684f9f643c0499126511a951ab68c3baa2.png', // 准时宝 
  transportation: 'https://img.ltwebstatic.com/images3_ccc/2024/07/25/5e/172189851893627cab3ffeb3882ef65864997756ab.png', // 运输保障 
  transportationSolid: 'https://shein.ltwebstatic.com/svgicons/2024/05/06/17149681663334976185.svg',
  couponNewGreen: 'https://img.ltwebstatic.com/images3_ccc/2024/08/06/d8/1722912338deb5edc5aa652ba67144682fee043297.png', // 优惠券新绿色图片
  couponNewOrange: 'https://img.ltwebstatic.com/images3_ccc/2024/08/06/7e/1722912490f0645dd8477a304ac08b7e0c5b22740a.png',// 优惠券新橙色图片
  orderFire: 'https://shein.ltwebstatic.com/svgicons/2024/08/10/17232574973768424519.svg',
  orderNewLow: 'https://shein.ltwebstatic.com/svgicons/2024/08/10/17232576942571725998.svg',
  orderTopList: 'https://shein.ltwebstatic.com/svgicons/2024/08/10/17232577882457886206.svg',
  orderLabel: 'https://shein.ltwebstatic.com/svgicons/2024/08/11/17233401681057616905.svg',
  orderCoin: 'https://shein.ltwebstatic.com/svgicons/2024/08/10/17232642851016611571.svg',
  orderTime: 'https://shein.ltwebstatic.com/svgicons/2024/08/11/17233403161474414658.svg',
  orderGuaranteePrice: 'https://shein.ltwebstatic.com/svgicons/2024/08/11/17233403763367249148.svg',
} 

import { template } from '@shein/common-function'

export const replaceSpanText = (text = '', cls = '', val = '', id = '') => {
  return template(`<span class="${cls}" id="${id}">${ val }</span>`, text)
}

export const wrapMinText = (id, h, fontSize, id1, fontSize1) => {
  const textNode = document.getElementById(id)
  if(textNode && textNode.clientHeight > h) {
    textNode.style.fontSize = fontSize
    if(id1) {
      const textNode1 = document.getElementById(id1) 
      if(textNode1) textNode1.style.fontSize = fontSize1
    }
    return textNode
  }
}

export const minText = (id, fontSize) => {
  const textNode = document.getElementById(id)
  if (!textNode) return

  if (textNode.clientWidth < textNode.scrollWidth) {
    textNode.style.fontSize = fontSize
  }
}
